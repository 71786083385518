import axios from "axios";

export const getOuputs = async (callback) => {
  try {
    const { data } = await axios.get("/api/outputs/all");
    const count = data.reduce(
      (prev, current) => prev + current.content.slice(3).length,
      0
    );
    callback(count);
  } catch (error) {
    console.log(error);
  }
};
