import React, { useState, useEffect } from "react";
import MegaEditorResults from "./MegaEditorResults";
import { tools } from "./writingTools";
import SerpTool from "./SerpTool";

const MegaEditorTools = ({ tool, switchFunc }) => {
  const [toolData, setToolData] = useState({
    title: "Engaging Story",
    description: "Write a creative story to hook your audience.",
    prompt: "Write an engaging story about",
    token: 85,
    engine: "",
    icon: "✍",
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (tools) {
      const results = tools.filter((doc) =>
        doc.title.toLowerCase().includes(searchTerm)
      );
      setSearchResults(results);
    }
  }, [searchTerm]);

  const handleClick = (title, desc, prompt, token, engine, icon) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
    setToolData({
      title: title,
      description: desc,
      prompt: prompt,
      token: token,
      engine: engine,
      icon: icon,
    });
  };

  return (
    <>
      <div className="row justify-content-start">
        <div className="col-lg-4 split-sidebar">
          <div className="toc-container">
            <h6 className="mt-2">All Tools</h6>
            <div className="grouped-inputs rounded-pill border">
              <div className="row g-0">
                <div className="col">
                  <input
                    type="text"
                    className="form-control form-control-sm  text-primary"
                    placeholder="Search tools"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <ul
              id="toc-nav"
              className="nav nav-minimal flex-column d-none d-lg-block"
            >
              <ul id="toolitems" className="nav nav-minimal flex-column">
                {searchResults.map((tool) => (
                  <li className="nav-item" key={tool.id}>
                    <span
                      onClick={() =>
                        handleClick(
                          tool.title,
                          tool.description,
                          tool.prompt,
                          tool.token,
                          tool.engine,
                          tool.icon
                        )
                      }
                      style={{ cursor: "pointer" }}
                      className="nav-link overflow-hidden"
                    >
                      <small>
                        {tool.icon} {tool.title}
                      </small>
                    </span>
                  </li>
                ))}
              </ul>
            </ul>
          </div>
        </div>
        <div className="col-lg-8 split-sidebar">
          {tool === "serp" ? (
            <SerpTool />
          ) : (
            <MegaEditorResults tools={toolData} runs={2} />
          )}
        </div>
      </div>
    </>
  );
};

export default MegaEditorTools;
