import React, { useState } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import Icon from "../../../../../common/Icon";
import axios from "axios";
import { toast } from "react-toastify";

const AiItem = ({ text, title }) => {
  const [isSave, setIsSave] = useState(false);
  function myFunction() {
    navigator.clipboard.writeText(text);
    toast.success("Copied!");
  }
  const handleSubmit = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ content: text, title });
    try {
      const {data} = await axios.post("/api/outputs/", body, config);
      if(data.message){
        setIsSave(false)
        return
      }
      setIsSave(true);
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <div className="item">
      <div className="item__icons">
        <Icon
          name="Save"
          onClick={handleSubmit}
          color={isSave ? "#d34f2d" : ''}
        />
        <Icon name="Copy" onClick={myFunction} />
      </div>
      <div
        className="item__content"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
};

AiItem.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
};

export default AiItem;
