/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import Input from "../../../common/Inputs/index";
import _ from "lodash";
import Icon from "../../../common/Icon";
import CustomSelect from "../../../common/Selects";
import { languages } from "../../../../utils/languageOptions";
import { connect } from "react-redux";
import { StyledTooltip } from "../../sections/SideBar/SideBar";
import { IconButton } from "@mui/material";
const PersonalSettings = ({ user, handleSave, invitedUser }) => {
  const [data, setData] = useState({
    language: "English",
    company: "",
    ...user,
  });
  useEffect(() => {
    setData({
      ...data,
      ...user,
    });
  }, [user]);
  const handleChangeInput = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };
  const [isEqual, setIsEqual] = useState(true);
  useEffect(() => {
    if (!_.isEqual(data, user)) {
      setIsEqual(false);
    } else {
      setIsEqual(true);
    }
  }, [data, user]);
  const onSave = (data) => {
    if(!isEqual){
      handleSave(data)
    }
  }
  return (
    <div className="personal__wrapper">
      <div className="personal__block">
        <div className="personal__title">Company Information</div>
        <div className="flex">
          <Input
            name="company"
            value={data && data.company && !invitedUser ? data.company : ""}
            label="Company name"
            disabled={!!invitedUser}
            placeholder="Enter a Company name"
            onChange={handleChangeInput}
          />
          <Input
            name="domain"
            value={data && data.domain && !invitedUser ? data.domain : ""}
            label="Primary website domain"
            placeholder="Enter a domain"
            disabled={!!invitedUser}
            onChange={handleChangeInput}
          />
        </div>
        <Input
          name="billing"
          value={data && data.billing && !invitedUser ? data.billing : ""}
          label="Billing email"
          disabled={!!invitedUser}
          placeholder="Enter a billing email"
          onChange={handleChangeInput}
        />
        <div>
          <button
            onClick={() => onSave(data)}
            className={`btn-second ${
              (isEqual || invitedUser) && "btn-second-disabled"
            } `}
            disabled={(isEqual || invitedUser)}
          >
            Update Information
          </button>
        </div>
      </div>
      <div className="personal__block">
        <div className="personal__title">Personal details</div>
        <Input
          name="name"
          value={data && data.name ? data.name : ""}
          label="Full Name"
          placeholder="Enter a full name"
          onChange={handleChangeInput}
        />
        <Input
          name="email"
          value={data && data.email ? data.email : ""}
          label="Email"
          onChange={handleChangeInput}
          disabled={true}
        />
        <div className="personal__block__buttons">
          <button
            onClick={() => onSave(data)}
            className={`btn-second ${isEqual && "btn-second-disabled"} `}
            disabled={(isEqual)}
          >
            Update Information
          </button>
        </div>
      </div>
      <div className="personal__block">
        <div className="personal__title">Set a new Password</div>

        <div className="flex-row-new">
          <Input
            name="password"
            value={data && data.password ? data.password : ""}
            label="New Password"
            type="password"
            placeholder="Enter a new password"
            onChange={handleChangeInput}
          />
          <Input
            name="passwordConfirmation"
            value={
              data && data.passwordConfirmation ? data.passwordConfirmation : ""
            }
            label="Confirm Password"
            placeholder="Confirm a new password"
            type="password"
            onChange={handleChangeInput}
          />
        </div>
        <div>
          <button
            onClick={() => {
              onSave({
                ...data,
                isReset: true,
              });
              setData({
                ...data,
                passwordConfirmation: null,
                password: null,
              });
            }}
            disabled={(isEqual)}
            className={`btn-second ${isEqual && "btn-second-disabled"} `}
          >
            Update password
          </button>
        </div>
      </div>
      <div className="personal__block">
        <div className="personal__title">
          Language defaults
          <div className="input__top__tooltip">
            <StyledTooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "common.black",
                    "& .MuiTooltip-arrow": {
                      color: "common.black",
                    },
                  },
                },
              }}
              title="The language in which you can enter information to generate content and the language in which you will receive content"
              placement="right"
            >
              <IconButton>
                <Icon name="Tooltip" />
              </IconButton>
            </StyledTooltip>
          </div>
        </div>
        <CustomSelect
          name="language"
          options={languages}
          value={data.language}
          label="Language of Output"
          onChange={handleChangeInput}
        />
        <div>
          <button
            onClick={() => onSave(data)}
            disabled={(isEqual)}
            className={`btn-second ${isEqual && "btn-second-disabled"} `}
          >
            Update Information
          </button>
        </div>
      </div>
    </div>
  );
};

PersonalSettings.propTypes = {
  user: PropTypes.object,
  handleSave: PropTypes.func,
  invitedUser: PropTypes.object,
};
const mapStatetoProps = (state) => ({});
export default connect(mapStatetoProps, {})(PersonalSettings);
