import React, { useEffect, useState } from "react";
import LandingSliderRight from "./LandingSliderRight";
import LandingSliderLeft from "./LandinSliderLeft";
import "./index.scss";

const LandingSlider = () => {
  const [selectedTab, setSelectedTab] = useState("first");
  return (
    <div className="landing__carousel"  >
      <LandingSliderLeft
        selectedVariant={selectedTab}
        setSelectedVariant={setSelectedTab}
      />
      <LandingSliderRight selectedVariant={selectedTab} />
    </div>
  );
};

export default LandingSlider;
