import { 
  GET_DOCUMENT, 
  GET_DOCUMENTS, 
  CLEAR_ERRORS,
  DOCUMENT_LOADING, 
  SET_DOCUMENT_ID
} from "./types";
import axios from 'axios';

export const setId = id => dispatch => {
  dispatch({
    type: SET_DOCUMENT_ID,
    payload: id
  })
}

export const setDoc = id => dispatch => {
  dispatch({
    type: GET_DOCUMENT,
    payload:{}
  })
}

// Get Doc
export const getDoc = id => dispatch => {
  dispatch(setDocLoading());
  axios
    .get(`/api/documents/findone/${id}`)
    .then(res =>{
      dispatch({
        type: GET_DOCUMENT,
        payload: res.data
      })
    })
    .catch(err =>
      dispatch({
        type: GET_DOCUMENT,
        payload: null
      })
    );
};

// Get Docs
export const getDocs = () => dispatch => {
  dispatch(setDocLoading());
  axios
    .get('/api/documents/all')
    .then(res =>
      dispatch({
        type: GET_DOCUMENTS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_DOCUMENTS,
        payload: null
      })
    );
};



// Set loading state
export const setDocLoading = () => {
  return {
    type: DOCUMENT_LOADING
  };
};

// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
