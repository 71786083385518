import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./index.scss";
import image from "../../../../../assets/images/joinUs.png";
import users from "../../../../../assets/images/userList.png";
import axios from "axios";
import { useIsMounted } from "../../../../../utils/useIsMounted";
const DashboardSection = () => {
  const [length, setLength] = useState(0);
  const isMountedRef = useIsMounted();

  const getLength = async () => {
    return await axios.get("/api/users/all");
  }
  useEffect(() => {
    getLength().then(({data})=>{
      if (data.length && isMountedRef.current) {
        setLength(data.length);
      }
    });
  }, [isMountedRef]);
  return (
    <div className="dashboard__section">
      <div className="dashboard__section__left">
        <div className="dashboard__section__left__users">
          <img src={users} alt="" />
        </div>
        <div className="dashboard__section__left__text">
          <div className="title">Join Us</div>
          <div className="text">
          Learn from other experts leading the AI copywriting and content creation space. We have users from startups and top companies in all areas of business. Join below!

          </div>
        </div>
        <div>
          <a className="btn-main" target='_blank' href='https://www.facebook.com/groups/474436847390154' rel="noreferrer">
            Join Community
          </a>
        </div>
      </div>
      <div className="dashboard__section__right">
        <img src={image} alt="" />
      </div>
    </div>
  );
};

export default DashboardSection;
