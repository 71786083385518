import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

const AddBlog = () => {
  const [formData, setFormData] = useState({
    img: "",
    link: "",
    title: "",
    tags: "",
  });

  const { img, title, link, tags } = formData;
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/api/documents/addblog", {
        img,
        title,
        link,
        tags,
      });
      toast.success("Blog added.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (err) {
      toast.error("Something went wrong.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <div className="container">
        <h3 className="mt-10">New blog article</h3>

        <form onSubmit={handleSubmit}>
          <div className="form-group mb-2">
            <label htmlFor="productName">
              <h6 className="font-weight-bold mb-2">Title of Article </h6>{" "}
            </label>
            <input
              type="text"
              className="form-control shadow-sm "
              name="title"
              value={title}
              onChange={handleChange}
              placeholder="e.g. How to use Research AI?"
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="productName">
              <h6 className="font-weight-bold mb-2">Link of Article </h6>{" "}
            </label>
            <input
              type="text"
              className="form-control shadow-sm "
              name="link"
              value={link}
              onChange={handleChange}
              placeholder="e.g. researchai.co/blog/new"
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="productName">
              <h6 className="font-weight-bold mb-2">Link of Img </h6>{" "}
            </label>
            <input
              type="text"
              className="form-control shadow-sm "
              name="img"
              value={img}
              onChange={handleChange}
              placeholder="e.g. researchai.co/blog/new"
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="productName">
              <h6 className="font-weight-bold mb-2">Tags</h6>{" "}
            </label>
            <input
              type="text"
              className="form-control shadow-sm "
              name="tags"
              value={tags}
              onChange={handleChange}
              placeholder="e.g. marketing, startups etc."
            />
          </div>

          <div className="form-group mb-5">
            <button
              type="submit"
              className="btn bg-primary inverted form-control shadow mt-5"
            >
              Add blog
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddBlog;
