import React, {useState, useEffect} from 'react'
import TextareaAutosize from 'react-textarea-autosize';
import axios from 'axios';

const ToolItem = ({content, title}) => {
  const [item, setItem] = useState(content)
  const [copy, setCopy] = useState('copy')
  const [save, setSave] = useState('save')
   const wordCount = item.split(" ").length

   function handleCopy (){
     setCopy('copy')
     navigator.clipboard.writeText(item)
     setCopy('copied')
    
   }
  
  const handleSubmit = async (e) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        }
      }
      const body = JSON.stringify({content, title});
      await axios.post("/api/outputs/", body, config);
      //  alert(response)
      setSave('saved')
      // alert(response.data)
     
    } catch (err) {
      console.error(err.message)
    }
   
    }

   useEffect(()=> {
     setTimeout(()=> {
         setCopy('copy')
     }, 700)
   
   }, [copy])


  return (
    <div>
      <div className="form-group">
         <TextareaAutosize className="form-control border-0"  minRows="3"
          value={item} onChange={e => setItem(e.target.value)}
         
         ></TextareaAutosize>
         <div className="d-flex flex-row-reverse p-2">
          <p className="small text-secondary"> Word count:{wordCount}</p>  
            <span style={{marginRight: '10px', cursor: 'pointer'}} 
              className=' small text-secondary' onClick={handleCopy}><i className="bi bi-files"></i> {copy}
            </span>
            <span style={{marginRight: '10px', cursor: 'pointer'}} 
              className=' small text-secondary' onClick={() =>handleSubmit()}><i className={save === 'saved' ? `text-danger bi bi-heart` : 'bi bi-heart'}></i> {save}
            </span>
         </div>
       
      </div>
       <hr />
    </div>
  )
}

export default ToolItem
