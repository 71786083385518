import React, { useState } from "react";
import axios from "axios";
import ToolItem from "../private/sections/tools/ToolItem";
import Loading from "../common/Loading";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../layout/Header";
import Footer2 from "../layout/Footer2";

toast.configure();

const ToolForm = ({ task, platform, header, text }) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    tone: "",
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { title, description, tone } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let titleP = title.length ? title : "";
    let toneP = tone.length ? tone : "";
    let prompt = `Write a ${toneP} Instagram post caption about: ${titleP}, ${description}.`;

    try {
      setLoading(true);
      const promise = await axios.post("/api/index/free", { text: prompt });
      const list = promise.data["choices"];
      setData(list);
      setLoading(false);
    } catch (err) {
      toast.error("You can do a maximum of 3 request per day.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
    }
  };

  function renderList() {
    if (!loading && data.length) {
      return data.map((item, id) => (
        <div>
          <ToolItem
            className="mb-15 text-navy  bg-light rounded"
            key={id}
            content={item.text}
          />
        </div>
      ));
    }
  }

  return (
    <div>
      <Header />
      <div className="container mt-20">
        <h3>Create a Social Media Post Caption.</h3>

        <form onSubmit={handleSubmit}>
          <div className="form-group mb-2">
            <label htmlFor="productName">
              <h6 className="font-weight-bold mb-2">
                Product name <span className="text-secondary">(Optional)</span>
              </h6>{" "}
            </label>
            <input
              type="text"
              className="form-control shadow-sm "
              name="title"
              value={title}
              onChange={handleChange}
              placeholder="e.g. Research AI"
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="productDesc">
              <h6 className="font-weight-bold mb-2">
                Description{" "}
                <span className="text-secondary">
                  {" "}
                  We'll generate ideas based on this.
                </span>
              </h6>{" "}
            </label>
            <textarea
              className="form-control shadow-sm"
              id=""
              rows="4"
              name="description"
              value={description}
              onChange={handleChange}
              placeholder="e.g. a software tool that helps entrepreneurs, freelancers and marketing teams produce awesome copies."
            ></textarea>
          </div>
          <div className="form-group col-5 mb-2">
            <label htmlFor="productName">
              <h6 className="font-weight-bold mb-2">
                Tone of voice <span className="text-secondary">(Optional)</span>
              </h6>{" "}
            </label>
            <input
              type="text"
              className="form-control shadow-sm "
              name="tone"
              value={tone}
              onChange={handleChange}
              placeholder="e.g. Creative"
            />
          </div>
          <div className="form-group mb-5">
            <button
              type="submit"
              className="btn bg-primary inverted form-control shadow-sm"
            >
              Generate
            </button>
          </div>
        </form>
        {loading ? <Loading /> : renderList()}
      </div>

      <Footer2 />
    </div>
  );
};

export default ToolForm;
