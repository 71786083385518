import { tools } from "../components/private/sections/newWritingTools";

export const getTemplatesByIcon = (type) => {
  switch (type) {
    case "All":
      return tools;
    case "Email":
      return tools.filter((elem) => elem.icon === "Email");
    case "Website":
      return tools.filter((elem) => elem.icon === "WebSite");
    case "Blog":
      return tools.filter((elem) => elem.icon === "Blog");
    case "Video":
      return tools.filter((elem) => elem.icon === "Video");
    case "Frameworks":
      return tools.filter((elem) => elem.icon === "Framework");
    case "E-commerce":
      return tools.filter((elem) => elem.icon === "EcomProduct");
    case "Social media":
      return tools.filter((elem) => elem.icon === "Social");
    default:
      return tools.filter((elem) => elem.icon === "Adds");
  }
};
