import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'

const Refer = () => {
  return (
    <div>
    <Header />
     <div className="hero-banner-ten bg-image  position-relative space-top-3 space-top-lg-4 space-bottom-2 space-bottom-lg-3">
    <div className="container">
     <div className="title-style-five text-center mb-50 md-mb-40">
      <h3>How referrals work</h3>
    </div>
      <div className="row container position-relative z-index-2 justify-content-lg-between mb-7">
        <p>You can get 1 month of FREE subscription to use Research AI by referring your 
        friends to sign up. To get it, simply ask your friends to enter your name on referral box
        when registering. Once your friend sucessfully subscribes to use Research AI, we'll add 1 month free to your account.
        You can refer as many friends as you want and get referral bonuses for all of them. 12 referrals means a whole year of FREE subscription.
        </p>
      </div>
      </div>
      </div>
      <Footer />
    </div>
  )
}

export default Refer
