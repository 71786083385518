export const plans = {
  "$0.00": {
    name: "Free plan",
    limit: "2k word limit",
    price: "$0.00",
    wordsLimit: 2000,
    // code: "price_1JiYXQJotKJS6JVSttXRhvj3",
    isAnnually: false,
    code: "price_1LfMAoHUtEEoDk2UkRPDJzjN",
  },
  "$19.99": {
    name: "Free plan",
    limit: "20k word limit",
    price: "$19.99",
    wordsLimit: 20000,
    // code: "price_1JiYXQJotKJS6JVSttXRhvj3",
    isAnnually: false,
    // code: "price_1LfMAoHUtEEoDk2UkRPDJzjN",
  },
  "$29.99": {
    name: "Standard plan",
    limit: "20k word limit",
    price: "$29.99",
    wordsLimit: 20000,
    // code: "price_1JiYXQJotKJS6JVSttXRhvj3",
    isAnnually: false,
    code: "price_1LFyujHUtEEoDk2UuvTGpSv9",
  },
  "$228.00": {
    name: "Standard plan",
    limit: "20k word limit",
    price: "$19.00",
    wordsLimit: 20000,
    // code: "price_1JiYXQJotKJS6JVSttXRhvj3",
    isAnnually: true,
    code: "price_1LfMdmHUtEEoDk2Ua2yfUWVN",
  },
  "$69.00": {
    name: "Pro plan",
    limit: "50k word limit",
    price: "$69.00",
    wordsLimit: 50000,
    // code: "price_1JiYXQJotKJS6JVSttXRhvj3",
    isAnnually: false,
    code: "price_1LfMuiHUtEEoDk2UjpgAxLkp",
  },
  "$59.00" : {
    name: "Pro plan",
    limit: "350k+ word limit",
    price: "$59.00",
    wordsLimit: 1,
    // code: "price_1JiYXQJotKJS6JVSttXRhvj3",
    isAnnually: false,
  },
  "$99.00": {
    name: "Pro plan",
    limit: "100k word limit",
    price: "$99.00",
    wordsLimit: 100000,
    // code: "price_1JiYXQJotKJS6JVSttXRhvj3",
    isAnnually: false,
    code: "price_1LfMvMHUtEEoDk2UpXFOsOpY",
  },
  "$249.00": {
    name: "Pro plan",
    limit: "350k+ word limit",
    price: "$249.00",
    wordsLimit: 1,
    // code: "price_1JiYXQJotKJS6JVSttXRhvj3",
    isAnnually: false,
    code: "price_1LfMw9HUtEEoDk2Umz9P7u7N",
  },
  "$660.00": {
    name: "Pro plan",
    limit: "50k word limit",
    price: "$55.00",
    wordsLimit: 50000,
    // code: "price_1JiYXQJotKJS6JVSttXRhvj3",
    isAnnually: true,
    code: "price_1LfN84HUtEEoDk2UwMP7PAZw",
  },
  "$948.00": {
    name: "Pro plan",
    limit: "100k word limit",
    price: "$79.00",
    wordsLimit: 100000,
    // code: "price_1JiYXQJotKJS6JVSttXRhvj3",
    isAnnually: true,
    code: "price_1LfN8OHUtEEoDk2UZtkOvZwV",
  },
  "$2,388.00": {
    name: "Pro plan",
    limit: "350k+ word limit",
    price: "$199.00",
    wordsLimit: 1,
    // code: "price_1JiYXQJotKJS6JVSttXRhvj3",
    isAnnually: true,
    code: "price_1LfN8jHUtEEoDk2UPC823Nvi",
  },
  // "$19.99": {
  //   name: "Basic plan",
  //   limit: "30k word limit",
  //   price: "$19.99",
  //   // code: "price_1JiYXQJotKJS6JVSttXRhvj3",
  //   isAnnually: false,
  //   code: "price_1LFyujHUtEEoDk2Uet12MU2l",
  // },
  // "$29.99": {
  //   name: "Standart plan",
  //   limit: "60k word limit",
  //   price: "$29.99",
  //   isAnnually: false,
  //   // code: "price_1KGXGGJotKJS6JVSoq5YSypf",
  //   code: "price_1LFyujHUtEEoDk2UuvTGpSv9",
  // },
  // "$59.00": {
  //   name: "Pro plan",
  //   limit: "Unlimited",
  //   price: "$59.00",
  //   isAnnually: false,
  //   // code: "price_1KC82UJotKJS6JVSnAg4bOkb",
  //   code: "price_1LFyujHUtEEoDk2UTQcGcOvq",
  // },
  // "$17.99": {
  //   name: "Basic plan",
  //   limit: "360k word limit",
  //   price: "$17.99",
  //   isAnnuall: true,
  //   code: "price_1LL3frHUtEEoDk2UjkjbFszh",
  // },
  // "$215.88": {
  //   name: "Basic plan",
  //   limit: "360k word limit",
  //   price: "$17.99",
  //   isAnnuall: true,
  //   code: "price_1LL3frHUtEEoDk2UjkjbFszh",
  // },
  // "$323.88": {
  //   name: "Standart plan",
  //   limit: "720k word limit",
  //   isAnnuall: true,
  //   price: "$26.99",
  //   // code: "price_1JiYXQJotKJS6JVSttXRhvj3",
  //   code: "price_1LL3eqHUtEEoDk2U2IYIDVCb",
  // },
  // "$648.00": {
  //   name: "Pro plan",
  //   limit: "Unlimited",
  //   isAnnuall: true,
  //   price: "$54.00",
  //   // code: "price_1JiYXQJotKJS6JVSttXRhvj3",
  //   code: "price_1LL3f9HUtEEoDk2UnukKNBmi",
  // },
  // "$26.99": {
  //   name: "Standart plan",
  //   limit: "720k word limit",
  //   isAnnuall: true,
  //   price: "$26.99",
  //   // code: "price_1JiYXQJotKJS6JVSttXRhvj3",
  //   code: "price_1LL3eqHUtEEoDk2U2IYIDVCb",
  // },
  // "$54.00": {
  //   name: "Pro plan",
  //   limit: "Unlimited",
  //   isAnnuall: true,
  //   price: "$54.00",
  //   // code: "price_1JiYXQJotKJS6JVSttXRhvj3",
  //   code: "price_1LL3f9HUtEEoDk2UnukKNBmi",
  // }
};
