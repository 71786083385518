import React from "react";
import PropTypes from "prop-types";
import "./index.scss";
import { v4 as uuidv4 } from "uuid";

export const TemplatesFilter = ({ selectedItem, setItem }) => {
  const types = [
    "All",
    "Email",
    "Website",
    "Blog",
    "Ads",
    "E-commerce",
    "Social media",
    "New",
    "Video",
    "Frameworks",
  ];
  return (
    <div className="templates__filter">
      {types.map((type) => {
        return (
          <div
            key={uuidv4()}
            className={`templates__filter__item ${
              selectedItem === type ? "templates__filter__item__active" : ""
            }`}
            onClick={() => setItem(type)}
          >
            {type}
          </div>
        );
      })}
    </div>
  );
};

TemplatesFilter.propTypes = {
  selectedItem: PropTypes.string,
  setItem: PropTypes.func,
};
