export const languages = [
  { value: "English", key: "🇺🇸 English (American)" },
  { value: "British English", key: "🇬🇧 English (British)" },
  { value: "Spanish", key: "🇪🇸 Spanish" },
  { value: "French", key: "🇫🇷 French" },
  { value: "Dutch", key: "🇳🇱 Dutch" },
  { value: "German", key: "🇩🇪 German" },
  { value: "Russian", key: "🇷🇺 Russian" },
  { value: "Italian", key: "🇮🇹 Italian" },
  { value: "Japanese", key: "🇯🇵 Japanese" },
  { value: "Polish", key: "🇵🇱 Polish" },
  { value: "Portuguese", key: "🇵🇹 Portuguese" },
  { value: "Brazilian Portuguese", key: "🇧🇷 Brazil" },
  { value: "Czech", key: "🇨🇿 Czech" },
  { value: "Danish", key: "🇩🇰 Danish" },
  { value: "Greek", key: "🇬🇷 Greek" },
  { value: "Swedish", key: "🇸🇪 Swedish" },
  { value: "Chinese (simplified)", key: "🇨🇳 Chinese (simplified)" },
];
