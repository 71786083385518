import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

const marks = [
  {
    value: 0,
    label: "50k",
  },
  {
    value: 50,
    label: "100k",
  },
  {
    value: 100,
    label: "350k+",
  },
];
function valuetext(value) {
  return `${value}k`;
}

function valueLabelFormat(value) {
  return marks.findIndex((mark) => mark.value === value) + 1;
}

const PlanSize = ({ selectedCount, onSelect, location }) => {
  const [defaultState, setDefaultState] = useState(null);
  const handleChange = (e, value) => {
    switch (value) {
      case 0:
        onSelect("50k");
        break;
      case 50:
        onSelect("100k");
        break;
      default:
        onSelect("300k");
    }
  };
  const handleGetDefault = () => {
    switch (selectedCount) {
      case "50k":
        setDefaultState(0);
        break;
      case "100k":
        setDefaultState(50);
        break;
      default:
        setDefaultState(100);
    }
  };
  useEffect(()=>{
    handleGetDefault()
  },[selectedCount])
  // return (
  //   <div className="plan__size">
  //     <div
  //       className="plan__size__background"
  //       style={{
  //         width:
  //           selectedCount === "300k"
  //             ? "99%"
  //             : selectedCount === "50k"
  //             ? "0%"
  //             : "50%",
  //       }}
  //     ></div>
  //     <div
  //       className="plan__size__item"
  //       role="presentation"
  //       onClick={() => onSelect("50k")}
  //     >
  //       {selectedCount === "50k" && (
  //         <div className="plan__size__circle__active"></div>
  //       )}
  //       <div className="plan__size__circle__inactive"></div>
  //       <div className="plan__size__price">50k words</div>
  //     </div>
  //     <div
  //       className="plan__size__item plan__size__item-2"
  //       role="presentation"
  //       onClick={() => onSelect("100k")}
  //     >
  //       {selectedCount === "100k" && (
  //         <div className="plan__size__circle__active"></div>
  //       )}
  //       {selectedCount === "50k" ? <div className="plan__size__circle"></div> : (
  //         <div className="plan__size__circle__inactive"></div>
  //       )}
  //       <div className="plan__size__price">100k words</div>
  //     </div>
  //     <div
  //       className="plan__size__item plan__size__item-3"
  //       role="presentation"
  //       onClick={() => onSelect("300k")}
  //     >
  //       {selectedCount === "300k" && (
  //         <div className="plan__size__circle__active"></div>
  //       )}
  //       {selectedCount !== "300k" && <div className="plan__size__circle"></div>}
  //       <div className="plan__size__price">300k+ words</div>
  //     </div>
  //   </div>
  // );
  return (
    <div>
      <Box sx={{ width: 232 }}>
        <Slider
          aria-label="Restricted values"
          defaultValue={0}
          value={defaultState}
          valueLabelFormat={valueLabelFormat}
          getAriaValueText={valuetext}
          step={null}
          marks={marks}
          onChange={handleChange}
          sx={{
            "& .MuiSlider-markLabel": {
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "150%",
              color: "#f0f1ff",
            },
            "&.MuiSlider-rail": {
              backgroundColor: "#fff",
            },
            "&.MuiSlider-root": {
              color: "#fff",
              marginBottom: "0px",
              marginTop: "26px",
            },
          }}
        />
      </Box>
    </div>
  );
};

PlanSize.propTypes = {};

export default PlanSize;
