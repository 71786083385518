/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";
import { toast } from "react-toastify";
import "./index.scss";
import Loading from "../../../common/Loading";
import SideBar from "../SideBar/SideBar.jsx";
import TextareaAutosize from "react-textarea-autosize";

toast.configure();

const Outputs = ({ auth: { user } }) => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = "";
  const [copy, setCopy] = useState("copy");

  function handleCopy(item) {
    navigator.clipboard.writeText(item);
    toast.success('Copied!')
  }

  useEffect(() => {
    setTimeout(() => {
      setCopy("copy");
    }, 300);
  }, [copy]);

  useEffect(() => {
    if (data && !loading) {
      const results = data.filter((doc) =>
        doc.content.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(results);
    }
  }, [searchTerm]);

  useEffect(() => {
    const loadDocs = async () => {
      setLoading(true);
      try {
        const res = await axios.get("/api/outputs/all");
        const data = res.data;

        setData(data);
        setSearchResults(data);
        setLoading(false);
      } catch (err) {
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      }
    };

    loadDocs();
  }, []);

  return (
    <div>
      {
        // <NavBar />  <Hero user={user && user.name}/>
      }
      <div className="ouputs">
        <SideBar user={user && user.name} />
        <div className="outputs__container">
          <div className="wrapper">
            <div className=" column-divider-lg ">
              <div className="ml-lg-2">
                <div className="row mx-n2 mb-7" style={{ justifyContent: 'center' }}>
                  <div className="container-fluid">
                    <h4 className="text-center ">My Outputs</h4>
                    <p className="mb-5 text-center">
                      All your saved AI outputs live here.
                    </p>
                    <div className="grouped-inputs p-1 rounded-pill border mb-5">
                      <div className="row g-0">
                        <div className="col">
                          <input
                            type="text"
                            className="form-control form-control-sm px-4 text-primary"
                            placeholder="Search for Outputs"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                        </div>
                        <div className="col-auto d-grid">
                          <span className="btn btn-primary btn-sm btn-icon rounded-circle">
                            <i className="bi bi-search"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row g-4 g-xl-5 justify-content-between mt-5">
                    {loading ? (
                      <Loading />
                    ) : (
                      searchResults.map((d) => (
                        <div key={d._id} className="col-md-6 ">
                          <h6>
                            Template name:{" "}
                            <span className="text-secondary">{d.title}</span>{" "}
                          </h6>
                          <TextareaAutosize
                            style={{ width: "100%" }}
                            className="border-0  rounded shadow-sm p-3"
                            minRows="3"
                            value={d.content}
                            onChange={(e) => setItem(e.target.value)}
                          ></TextareaAutosize>

                          <div className="d-flex flex-row p-2">
                            <div className="">
                              <span
                                style={{ marginRight: "10px" }}
                                className=" small text-secondary"
                              >
                                <i className="bi bi-files"></i> Word count:{" "}
                                {d.content.split(" ").length}
                              </span>
                              <span
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer",
                                }}
                                className=" small text-secondary"
                                onClick={() => handleCopy(d.content)}
                              >
                                <i className="bi bi-files"></i> {copy}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Outputs.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStatetoProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStatetoProps)(Outputs);
