import React, { useState } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import { useHistory } from "react-router";

const Warning = (props) => {
  const location = useHistory();
  const [isWarning, setIsWarning] = useState(localStorage.getItem("warning"));
  if(isWarning === 'off'){
      return null
  }
  return (
    <div className="warning__words">
          <div className="warning__words__left">
            <div className="title">Your Word Limit Is About To Run Out!</div>
            <div className="subTitle">
              You will soon reach your monthly word limit. Please upgrade your
              plan to increase your word limit.
            </div>
          </div>
          <div className="warning__words__right">
            <button
              className="btn-third"
              onClick={() => {
                localStorage.setItem("warning", "off")
                setIsWarning('off')
              }}
            >
              OK
            </button>
            <button
              className="btn-fourth"
              onClick={() => location.push("/settings#billing")}
            >
              Upgrade
            </button>
          </div>
    </div>
  );
};

Warning.propTypes = {};

export default Warning;
