import React, { useState } from "react";
import StepWizard from "react-step-wizard";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { useHistory } from "react-router";
import axios from "axios";

const BeastMode = () => {
  let history = useHistory();
  const [formData, setFormData] = useState({
    keyword: "",
    description: "",
    title: "",
    intro: "",
    outline: "",
  });
  const { keyword, description, title, intro, outline } = formData;

  const handleChange = (keyword, description) => {
    setFormData({ ...formData, keyword: keyword, description: description });
  };
  const handleChangeStep2 = (title) => {
    setFormData({ ...formData, title: title });
  };
  const handleChangeStep3 = (intro) => {
    setFormData({ ...formData, intro: intro });
  };
  const handleChangeStep4 = (outline) => {
    setFormData({ ...formData, outline: outline });
  };
  // Creates a new document
  const handleSubmit = async (e) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const content = `${outline} 
      
      ${intro}`;
      const body = JSON.stringify({ title, content });
      const response = await axios.post("/api/documents/", body, config);
      //  alert(response)
      const id = response.data.doc.id;
      history.push(`/documents/${id}`);
      // alert(response.data)
    } catch (err) {
      console.error(err.message);
    }
  };
  return (
    <div>
      <div className="container">
        <h2 className="text-center mt-10">New Blog Article.</h2>
        <StepWizard isHashEnabled={true}>
          <Step1 hashKey={"step1"} func={handleChange} />
          <Step2
            hashKey={"step2"}
            func={handleChangeStep2}
            description={description}
            keyword={keyword}
          />
          <Step4
            hashKey={"step3"}
            description={description}
            blogTitle={title}
            outline={outline}
            func={handleChangeStep4}
            startFunc={handleSubmit}
          />
          <Step3
            hashKey={"step4"}
            description={description}
            blogTitle={title}
            keyword={keyword}
            outline={outline}
            func={handleChangeStep3}
            startFunc={handleSubmit}
          />
        </StepWizard>
      </div>
    </div>
  );
};

export default BeastMode;
