import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon'
import './index.scss'
import Modal from '../Modal'

const DocumetnItemNew = ({title,id, onDelete, onSelect, name}) => {
    const [isOpenDeleteModal,setIsOpenDeleteModal] = useState(false)
    return (
       <div className="document">
           {isOpenDeleteModal && (
               <Modal
                    onClose={()=>setIsOpenDeleteModal(false)}
               >
                    <div className="delete__modal">
                        <div className="delete__modal__top">
                          <div className="delete__modal__title">
                            Are you sure you want to delete this document ?
                          </div>
                        </div>
                        <div className="delete__modal__footer">
                          <button className="btn-second" onClick={() => setIsOpenDeleteModal(false)}>
                            Cancel
                          </button>
                          <button className="btn-main" onClick={() => {
                              setIsOpenDeleteModal(false)
                              onDelete()
                          }}>
                            Delete
                          </button>
                        </div>
                    </div>
               </Modal>
           )}
          <div className="document__left" role='presentation' onClick={()=>onSelect()}>
          <div className='document__container'>
            <div className="document__icon">
                  <Icon name='DocumentNew' />
            </div>
            <div className="document__title">
                {name}
            </div>
          </div>
           <div className="document__title">
               {title}
           </div>
          </div>
          <div className="documetn__right" role='presentation' onClick={() => setIsOpenDeleteModal(true)}>
              <Icon name='Trash' />
          </div>
       </div>
    );
};

DocumetnItemNew.propTypes = {
    title: PropTypes.any,
    onDelete: PropTypes.func,
    id: PropTypes.any,
    onSelect: PropTypes.func,
    name: PropTypes.func
};

export default DocumetnItemNew;