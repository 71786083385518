import React from "react";
import "./index.scss";
import CommentBlock from "../../common/CommentBlock";
import img1 from "../../../assets/images/user1.png";
import img2 from "../../../assets/images/user2.png";
import img3 from "../../../assets/images/user3.png";
import img4 from "../../../assets/images/user4.png";
import img5 from "../../../assets/images/user5.png";
import img6 from "../../../assets/images/user6.png";
import img7 from "../../../assets/images/user7.png";
import img8 from "../../../assets/images/user8.png";
import { useWindowSizeChange } from "utils/useWindowSizeChange";

const Comments = () => {
  const windowSize = useWindowSizeChange();
  return (
    <div className="about__comments">
      <div className="about__comments__wrapper">
        <div className="about__comments__title">
          Look What Other Creators Are <br /> Saying About{" "}
          <span>ResearchAI</span>
        </div>
        <div className="about__comments__content">
          <div className="about__comments__content__item">
            <CommentBlock
              version=""
              description="The platform is easy to use and navigate. I like that you can generate each section step-by-step with ease. The integration of SEMrush is a big win for Research AI!"
              account={{
                title: "Martha Goldman",
                description: "Design Agency Owner",
                text: "👍",
                src: img1,
              }}
              title="A writing tool for organic SEO!"
            ></CommentBlock>
            <CommentBlock
              version="white"
              title="Can be used for all types of contents!"
              account={{
                title: "Cheyenne Flint",
                description: "Copywriter",
                text: "🎉",
                src: img2,
              }}
              description="I found ResearchAI application to be great! It generated content at a faster pace than other AI tools! It made writing fun for me."
            ></CommentBlock>
          </div>
          <div className="about__comments__content__item item-40">
            <CommentBlock
              version=""
              description="ResearchAI has transformed my writing from basic to amazing. Once I enter my keyword it spits out thoroughly researched verbiage. You can select the tone and length of copy you need. This tool has helped me a lot."
              account={{
                title: "Alexandra Tyler",
                description: "Marketer",
                text: "😻",
                src: img8,
              }}
              title="One of the beat AI tools in the Market"
            ></CommentBlock>

            <CommentBlock
              version=""
              description="Before I discovered ResearchAI, I spent tons of money on copy, even at that it was never to my satisfaction. However, now I just pay a fraction of that cost to get copy that helps my business grow."
              account={{
                title: "Luke Davidson",
                description: "Design Agency Owner",
                text: "👀",
                src: img4,
              }}
              title="A Tool like no other"
            ></CommentBlock>
          </div>
          {windowSize.innerWidth > 1023 && (
            <>
              <div className="about__comments__content__item">
                <CommentBlock
                  version=""
                  description="I always struggled with writing which took the fun out of being creative with writing. However, since I discovered ResearchAI I have been able to get that passion back. The content it generates sparks my creativity and helps get the content pouring."
                  account={{
                    title: "Daniel White",
                    description: "Lawyer",
                    text: "🚀",
                    src: img5,
                  }}
                  title="Makes writing a breeze"
                ></CommentBlock>
                <CommentBlock
                  version="white"
                  title="Awesome and to the point!"
                  account={{
                    title: "Gustavo Petro",
                    description: "Designer",
                    text: "🔥",
                    src: img3,
                  }}
                  description="I love how much time ResearchAI saves me, I used to go from spending hours each week coming up with content to now generating content in one click."
                ></CommentBlock>
              </div>
              <div className="about__comments__content__item item-20">
                <CommentBlock
                  version="white"
                  description="For my agency, ResearchAI has been a game changer. With the click of a button, I can create a complete landing page. I receive 5 different variations of content so that I can choose the one that I like best."
                  account={{
                    title: "Angela Sanchez",
                    description: "Web design Agency Owner",
                    text: "👏",
                    src: img7,
                  }}
                  title="A game changer for creative agencies"
                ></CommentBlock>
                <CommentBlock
                  version="white"
                  description="The interface is lovely. I found it very easy to use. The templates where so helpful and useful for what I needed."
                  account={{
                    title: "Dorita Alvaro",
                    description: "Writer",
                    text: "❤️",
                    src: img6,
                  }}
                  title="Lovely Tool"
                ></CommentBlock>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Comments;
