import {
  ADD_DOCUMENT,
  GET_DOCUMENT,
  GET_DOCUMENTS,
  DELETE_DOCUMENT,
  DOCUMENT_LOADING,
  SET_DOCUMENT_ID,
} from "../actions/types";

const initialState = {
  documents: [],
  document: {},
  loading: false,
  id: null,
};

export default function documentReducer(state = initialState, action) {
  switch (action.type) {
    case DOCUMENT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
        loading: false,
      };
    case SET_DOCUMENT_ID:
      return{
        ...state,
        id: action.payload
      }
    case GET_DOCUMENT:
      return {
        ...state,
        document: action.payload,
        loading: false,
      };
    case ADD_DOCUMENT:
      return {
        ...state,
        documents: [action.payload, ...state.posts],
      };
    case DELETE_DOCUMENT:
      return {
        ...state,
        documents: state.documents.filter(
          (document) => document._id !== action.payload
        ),
      };
    default:
      return state;
  }
}
