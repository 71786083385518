import React from 'react'
import {Link} from 'react-router-dom'

const Footer = () => {
  return (
    <div>
    <footer className="theme-footer-four">
      <div className="top-footer">
        <div className="container">
          <div className="row justify-content-end">
            <div id="contact" className="col-xl-4 col-lg-3 col-12 footer-about-widget">
              <ul className="font-rubik mt-10">
              
              <h5 className="footer-title">Have questions?</h5>
              
              <li><a href="mailto:researchai.co@gmail.com">researchai.co@gmail.com</a></li>
              <li><a href="tel:4157261977">+1 (415) 726-1977</a></li>
             
                <li></li>
              </ul>
            </div> 
            <div className="col-lg-4 col-md-4 footer-list">
              <h5 className="footer-title font-slab">Links</h5>
              <ul>
                <li><Link to="/">Home</Link></li>

                <li><a href="/#pricing">Pricing</a></li>
                <li><a href="/#">About us</a></li>
                
              </ul>
            </div>
            <div className="col-lg-4 col-md-4 footer-list">
              <h5 className="footer-title font-slab">Legal</h5>
              <ul>
                
                <li><Link to="/terms">Terms &amp; conditions</Link></li>
                <li><Link to="/privacy">Privacy policy</Link></li>
              </ul>
            </div> 

          
          </div> 
        </div> 
      </div>
      <div className="container row">
      <div className="col-md-12 text-right">
        <p>Copyright @2021 Research AI.</p> 
      </div>
     
    </div>
    
    </footer>
    </div>
  )
}

export default Footer
