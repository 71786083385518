/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./index.scss";
// import logo from '../../../assets/images/'
import Icon from "../../common/Icon";
import { useLocation } from "react-router";

const Header = ({ login, isAuthenticated }) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <nav
        id="mainNav"
        style={{ backgroundColor: "black" }}
        className="navbar navbar-expand-lg navbar-sticky navbar-dark"
      >
        <div className="container">
          <Link to="/" className="navbar-brand">
            <Icon name="LogoLanding" />
          </Link>

          <ul className="navbar-nav navbar-nav-secondary order-lg-3">
            <li className="nav-item d-lg-none">
              <a
                className={`nav-link nav-icon ${isOpen && "nav-icon-active"}`}
                href=""
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbar"
                aria-controls="navbar"
                onClick={() => setIsOpen(!isOpen)}
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <div className="nav-icon-row"></div>
                <div className="nav-icon-row"></div>
                <div className="nav-icon-row"></div>
              </a>
            </li>
            <li className="nav-buttons">
              <Link to="/login" className="btn-third">
                Login
              </Link>
              <Link to="/register" className="btn-main">
                Sign Up FREE
              </Link>
            </li>
          </ul>

          <div
            className="collapse navbar-collapse"
            id="navbar"
            data-bs-parent="#mainNav"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  className="nav-link "
                  to="/free-tools"
                  id="navbarDropdown-1"
                >
                  Free Tools
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link "
                  href="/#how-it-works"
                  id="navbarDropdown-1"
                >
                  How it works
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link " href="/#pricing" id="navbarDropdown-1">
                  <Link
                    className={`nav-link${
                      location.pathname === "/pricing" ? " nav-link-active" : ""
                    }`}
                    to="/pricing"
                    onClick={()=>window.scrollTo({top:0})}
                    id="navbarDropdown-1"
                  >
                    Pricing
                  </Link>
                </a>
              </li> */}
              <li className="nav-item">
                <a className="nav-link " href="/#faq" id="navbarDropdown-1">
                  FAQ
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link "
                  href="mailto:hello@researchai.co"
                  id="navbarDropdown-1"
                >
                  Contact
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " href="/blog" id="navbarDropdown-1">
                  Blog
                </a>
              </li>

              <li className="nav-item d-lg-none nav-item-buttons">
                <Link to="/login" className="btn-third">
                  Login
                </Link>
                <Link to="/register" className="btn-main">
                  Sign Up FREE
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Header);
