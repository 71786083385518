import React, { useState } from "react";
import axios from "axios";
import Item from "./tools/ToolItem";
import Loading from "../../common/Loading";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MegaEditorResults = ({ tools, runs }) => {
  const [formData, setFormData] = useState({
    name: "",
    desc: "",
    tone: "",
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState("");
  const { name, desc, tone } = formData;
  const { description, engine, title, prompt, token, icon } = tools;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let titleP = name.length ? name + "," : "";
    let toneP = tone.length ? `. Tone of voice is ${tone}.` : "";
    let lang = language.length ? `The output language is: ${language}.` : "";
    let apiPrompt = `${prompt} ${titleP}${desc}${toneP} ${lang}`;

    try {
      setLoading(true);

      const promise = await axios.post("/api/index/beastmode", {
        text: apiPrompt,
        engine: engine,
        token: token,
        runs: runs,
      });

      const list = promise.data["choices"];
      setData(list);
      setLoading(false);
    } catch (err) {
      toast.error("Something went wrong. Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
    }
  };

  function renderList() {
    if (!loading && data && data.length) {
      return data.map((item, id) => (
        <div key={id}>
          <Item
            className="mb-15 text-navy  bg-light rounded"
            key={id}
            content={item.text}
            title={title}
          />
        </div>
      ));
    }
  }
  return (
    <div className="toc-container" id="editor-results">
      <h6 className="mt-2">
        {icon} {title}
      </h6>
      <p className="text-secondary">{description}</p>
      <hr />

      <form onSubmit={handleSubmit}>
        <div className="form-group mb-2">
          <label htmlFor="productName">
            <h6 className="font-weight-bold mb-2">
              Product name <span className="text-secondary">(Optional)</span>
            </h6>{" "}
          </label>
          <input
            type="text"
            className="form-control shadow-sm "
            name="name"
            value={name}
            onChange={handleChange}
            placeholder="e.g. Research AI"
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="productDesc">
            <h6 className="font-weight-bold mb-2">
              Description{" "}
              <span className="text-secondary">
                {" "}
                We'll generate ideas based on this.
              </span>
            </h6>{" "}
          </label>
          <textarea
            className="form-control shadow-sm"
            id=""
            rows="3"
            name="desc"
            value={desc}
            onChange={handleChange}
            required
            placeholder="e.g. a software tool that helps entrepreneurs, freelancers and marketing teams produce awesome copies."
          ></textarea>
        </div>
        <div className="col-12 d-flex flex-row justify-content-between">
          <div className="form-group col-6 mb-2">
            <label htmlFor="productName">
              <h6 className="font-weight-bold mb-2">
                Tone of voice <span className="text-secondary">(Optional)</span>
              </h6>{" "}
            </label>
            <input
              type="text"
              className="form-control shadow-sm "
              name="tone"
              value={tone}
              onChange={handleChange}
              placeholder="e.g. Creative"
            />
          </div>
          <div className="form-group col-5 mb-2">
            <label htmlFor="productName">
              <h6 className="font-weight-bold mb-2">Language of Output</h6>{" "}
            </label>
            <select
              className="form-select"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <option value="English" selected>
                🇺🇸 English (American)
              </option>
              <option value="British English" selected>
                🇬🇧 English (British)
              </option>
              <option value="Spanish">🇪🇸 Spanish</option>
              <option value="French">🇫🇷 French</option>
              <option value="Dutch">🇳🇱 Dutch</option>
              <option value="German">🇩🇪 German</option>
              <option value="Russian">🇷🇺 Russian</option>
              <option value="Italian">🇮🇹 Italian</option>
              <option value="Japanese">🇯🇵 Japanese</option>
              <option value="Polish">🇵🇱 Polish</option>
              <option value="Portuguese">🇵🇹 Portuguese</option>
              <option value="Brazilian Portuguese">🇧🇷 Portuguese</option>
              <option value="Czech">🇨🇿 Czech</option>
              <option value="Danish">🇩🇰 Danish</option>
              <option value="Greek">🇬🇷 Greek</option>
              <option value="Swedish">🇸🇪 Swedish</option>
              <option value="Chinese (simplified)">
                🇨🇳 Chinese (simplified)
              </option>
            </select>
          </div>
        </div>

        <div className="form-group">
          {loading ? (
            <Loading />
          ) : (
            <button
              type="submit"
              className="btn bg-primary inverted form-control shadow mt-1"
            >
              Generate
            </button>
          )}
        </div>
      </form>
      {loading ? "" : renderList()}
    </div>
  );
};

export default MegaEditorResults;
