import React, { useState } from "react";
import axios from "axios";
import NavBar from "../NavBar";
import Editor from "rich-markdown-editor";
import "react-toastify/dist/ReactToastify.css";

const Resume = () => {
  const [formData, setFormData] = useState({
    position: "",
    experience: "",
  });
  const [loading, setLoading] = useState(false);
  const [bullets, setBullets] = useState("");
  const { position, experience } = formData;

  const onChangeFunc = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await axios.post("/api/index/content-brief", {
        text: `Context: ${experience} \nGiven the context, Make a list of bullet points about ${position} for a resume:`,
        token: 85,
      });
      const data = res.data["choices"][0]["text"];
      setBullets(data);
      setLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <div>
      <NavBar />
      <div className="container">
        <div className="d-flex flex-column justify-content-center">
          <h3>Example Resume Generation For Lisa</h3>
          <form onSubmit={handleSubmit} className="col-8">
            <div className="form-group mt-5">
              <label>What position are you applying for?</label>
              <input
                name="position"
                value={position}
                placeholder="HR Manager"
                type="text"
                className="form-control form-control-sm"
                onChange={(e) => onChangeFunc(e)}
                required
              />
            </div>
            <div className="form-group mt-5">
              <label>
                What are some the job related accomplishments/experience that
                you have?
              </label>
              <input
                name="experience"
                value={experience}
                placeholder="Managed team of 10, experience working with Payroll softwares (Zenefits), Excellent communication skills etc."
                type="text"
                className="form-control form-control-sm"
                onChange={(e) => onChangeFunc(e)}
                required
              />
            </div>
            {loading ? (
              <h5 className="mt-5">Creating a custom bullet list</h5>
            ) : (
              <button type="submit" className="btn btn-primary mt-5">
                Submit
              </button>
            )}
          </form>
          {bullets.length && !loading ? (
            <div>
              <h5 className="mt-5">
                Example bullet list for a resume based on previous experience
              </h5>
              <Editor
                className="mt-5 mb-10"
                defaultValue={bullets}
                onChange={(getValue) => {
                  setBullets(getValue());
                }}
                value={bullets}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Resume;
