import React, { useState } from "react";
import axios from "axios";
import Loading from "../../../common/Loading";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextareaAutosize from "react-textarea-autosize";

toast.configure();

const VideoToolForm = ({ task, platform, header, text }) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    tone: "",
  });
  const [loading, setLoading] = useState(false);
  const [script, setScript] = useState("");

  const { title, description, tone } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // let intro = "Write an attention-grabbing intro for the following commercial video ad script about "
    let intro =
      "Write an agitating commercial video ad script pain statement about";
    let solve =
      "Write a solution script for a commercial video ad for the following product";
    let callToAction =
      "Write benefits script for a commercial ad for the following product";
    let titleP = title.length ? title : "";
    let toneP = tone.length ? `Tone of voice is ${tone}.` : "";

    try {
      setLoading(true);
      //  Gets intro 1 step
      let prompt = `${intro}: ${titleP}, ${description}. ${toneP}`;

      const promise = await axios.post("/api/index/script-writer", {
        text: prompt,
      });
      const introRes = promise.data["choices"][0]["text"];
      // setIntro(introRes)
      // setScript({...script, introduction})
      // Uses intro as a prompt and gets pain statement
      let prompt2 = `${solve} ${titleP}, ${description}. ${toneP}`;

      const promise2 = await axios.post("/api/index/script-writer", {
        text: prompt2,
      });
      const solutionRes = promise2.data["choices"][0]["text"];
      // setSolution(solutionRes)
      // setScript({...script, solution})

      // uses pain statement as a prompt and gets cta
      let prompt3 = `${callToAction}: ${titleP}, ${description}`;

      const promise3 = await axios.post("/api/index/script-writer", {
        text: prompt3,
      });
      const ctaRes = promise3.data["choices"][0]["text"];
      // setCta(ctaRes)
      let finalContent =
        introRes +
        solutionRes +
        ctaRes +
        " Ready to get started? Go to our website.";

      setScript(finalContent);

      //  let finalContent = introRes + solutionRes + ctaRes + "Ready to get started? Go to our website."

      setLoading(false);
    } catch (err) {
      toast.error("Something went wrong. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="container">
        <h3 className="space-bottom-1">Commercial Ad Video Script Writer</h3>

        <form onSubmit={handleSubmit}>
          <div className="form-group mb-2">
            <label htmlFor="productName">
              <h6 className="font-weight-bold mb-2">
                Product name <span className="text-secondary">(Optional)</span>
              </h6>{" "}
            </label>
            <input
              type="text"
              className="form-control shadow-sm "
              name="title"
              value={title}
              onChange={handleChange}
              placeholder="e.g. Research AI"
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="productDesc">
              <h6 className="font-weight-bold mb-2">
                Describe the product for the video{" "}
                <span className="text-secondary">
                  {" "}
                  We'll generate ideas based on this.
                </span>
              </h6>{" "}
            </label>
            <textarea
              className="form-control shadow-sm"
              id=""
              rows="4"
              name="description"
              value={description}
              onChange={handleChange}
              placeholder="e.g. a software tool that helps entrepreneurs, freelancers and marketing teams produce awesome copies."
            ></textarea>
          </div>
          <div className="form-group col-5 mb-2">
            <label htmlFor="productName">
              <h6 className="font-weight-bold mb-2">
                Tone of voice <span className="text-secondary">(Optional)</span>
              </h6>{" "}
            </label>
            <input
              type="text"
              className="form-control shadow-sm "
              name="tone"
              value={tone}
              onChange={handleChange}
              placeholder="e.g. Creative"
            />
          </div>
          <div className="form-group ">
            <button
              type="submit"
              className="btn bg-primary inverted form-control shadow-sm mb-5"
            >
              Generate
            </button>
          </div>
        </form>
        {loading ? (
          <Loading />
        ) : (
          <div className=" space-1">
            <TextareaAutosize
              className="form-control mb-15 text-navy  rounded"
              minRows="3"
              value={script}
              onChange={(e) => setScript(e.target.value)}
            ></TextareaAutosize>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoToolForm;
