/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SideBar from "../sections/SideBar/SideBar";
import "./index.scss";
import axios from "axios";
import Icon from "../../common/Icon";
import { useHistory } from "react-router-dom";
import Loader from "../../common/Loader";
import { v4 as uuidv4 } from "uuid";

const Invoices = ({ auth: { user,canceled } }) => {
  const [loading, setLoading] = useState(false);
  const [invoicData, setInvoiceData] = useState([]);
  const history = useHistory();
  const getInvoices = async () => {
    return await axios.get(`/api/payments/invoices/${user._id}`);
  };
  useEffect(() => {
    console.log(canceled)
    if (user && user._id) {
      setLoading(true);
      getInvoices().then(({ data }) => {
        setLoading(false);
        setInvoiceData(data.list);
      });
    }
  }, []);
  const GoBack = () => {
    history.goBack();
  };
  return (
    <div className="invoices">
      {loading ? (
        <Loader />
      ) : (
        <>
          <SideBar user={user && user.name} />
          <div className="invoices__container"
            style={canceled ? {
              margin: '0px'
            }: {}}
          >
            <div className="wrapper">
              <div
                className="invoices__back"
                role="presentation"
                onClick={GoBack}
              >
                <Icon name="ArrowBack" />
                <span>Back</span>
              </div>
              <div className="wrapper__title">Invoices</div>
              <div className="invoice__table">
                <div className="invoice__table__header">
                  <div className="invoice__table__tr">
                    <div className="invoice__table__flex">
                      <div className="invoice__table__th">Invoice Number</div>
                      <div className="invoice__table__th">Status</div>
                    </div>
                    <div className="invoice__table__th t-end">Invoice PDF</div>
                  </div>
                </div>
                <div className="invoice__table__body">
                  {invoicData &&
                    invoicData.map((invoice) => {
                      if (invoice.invoice_pdf) {
                        return (
                          <div key={uuidv4()} className="invoice__table__tr">
                            <div className="invoice__table__flex">
                              <div className="invoice__table__td">
                                {invoice.number}
                              </div>
                              <div className="invoice__table__td">
                                <div className="invoice__table__status ">
                                  {invoice.status}
                                </div>
                              </div>
                            </div>
                            <div className="invoice__table__td t-end">
                              <button
                                className="btn-main"
                                onClick={() =>
                                  (window.location.href = invoice.invoice_pdf)
                                }
                              >
                                Download
                              </button>
                            </div>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

Invoices.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStatetoProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStatetoProps, mapDispatchToProps)(Invoices);
