import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import NavBar from "./sections/NavBar";

const Tutorials = ({ auth: { user } }) => {
  const data = [
    {
      link: "https://www.youtube.com/embed/V_91V1Ag2HI",
      title: "How to use Research AI?",
    },
    {
      link: "https://www.youtube.com/embed/xfawEP6TXRg",
      title: "How to write a job description using Research AI?",
    },
    {
      link: "https://www.youtube.com/embed/Kg99lGL7SLU",
      title: "How to write a blog article using AI?",
    },
  ];
  return (
    <div>
      <NavBar />
      <div className="faqs-inner-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h2>Tutorials</h2>
              <hr />
              If you are interested about particular use case, please{" "}
              <a
                className="text-primary strong"
                href="mailto:researchai.co@gmail.com"
              >
                send us a request
              </a>{" "}
              and we'll be happy to do a live demo.
            </div>
          </div>
          <div className="row col-md-12">
            <div className="d-flex align-content-start flex-wrap space-1">
              {data.map((d) => (
                <iframe
                  className="col-md-6 mb-25"
                  key={d.link}
                  width="560"
                  height="315"
                  src={d.link}
                  title={d.title}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Tutorials.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStatetoProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStatetoProps)(Tutorials);
