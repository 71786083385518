import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SideBar from "../SideBar/SideBar";
import { useHistory } from "react-router";
import "./index.scss";
import Icon from "../../../common/Icon";
import { Link } from "react-router-dom";
import { setTemplate } from "../../../../actions/templates";
import { tools } from "../newWritingTools";
import axios from 'axios'
import { setId } from "../../../../actions/document";

const CreateDocument = ({ auth: { user },changeTemplate,template,setInitialDocumentId }) => {
  let history = useHistory();
  const handleSubmit = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      changeTemplate(tools[6])
      const body = JSON.stringify({title:tools[6].title});
      const response = await axios.post("/api/documents/", body, config);
      const id = response.data.doc.id;
      setInitialDocumentId(id)
      history.push("/new-document/editor")
    } catch (err) {
      console.error(err.message);
    }
  };
  return (
    <div className="new__document">
      <SideBar user={user && user.name} />
      <div className="new__document__content">
        <div className="wrapper">
          <div className="wrapper__top wrapper__top__m">
            <div className="wrapper__title" >Create a new document</div>
            <div className="wrapper__subtitle">Choose your starting point</div>
          </div>
          <div className="new__document__links">
            <div
              role="presentation"
              className="new__document__link"
              onClick={() => {
                handleSubmit()
              }}
            >
              <div className="new__document__link__border link__scratch" />
              <div className="new__document__link__content">
                <div className="new__document__link__left">
                  <div className="new__document__link__left__title" >
                    Start from scratch
                  </div>
                  <div className="new__document__link__left__subtitle">
                    Create your unique content using our AI Generator
                  </div>
                </div>
                <div className="new__document__link__right">
                  <Icon name="ArrowLeft" />
                </div>
              </div>
            </div>
           <div className="new__document__link">
           <Link
              className="link__template new__document__link"
              to="/tools"
            >
              <div className="new__document__link__border link__outputs" />
              <div className="new__document__link__content">
                <div className="new__document__link__left">
                  <div className="new__document__link__left__title">
                    Create from Templates
                  </div>
                  <div className="new__document__link__left__subtitle">
                    Get help writing any type of content using our AI templates
                  </div>
                </div>
                <div className="new__document__link__right">
                  <Icon name="ArrowLeft" />
                </div>
              </div>
            </Link>
           </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CreateDocument.propTypes = {
  auth: PropTypes.object.isRequired,
  changeTemplate: PropTypes.func,
  template: PropTypes.object,
  setInitialDocumentId: PropTypes.func,
};
const mapStatetoProps = (state) => ({
  auth: state.auth,
  template: state.template,
});
const mapDispatchToProps = (dispatch) => ({
  changeTemplate: (template) => dispatch(setTemplate(template)),
  setInitialDocumentId: (id) => dispatch(setId(id))
})

export default connect(mapStatetoProps,mapDispatchToProps)(CreateDocument);
