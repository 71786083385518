import React from "react";
import PropTypes from "prop-types";
import "./index.scss";
import CardTemplate from "../../../../../../components/common/Card/index";

const TemplatesItems = ({ templates, handleSelect }) => {
  return (
    <div className="ai__templates">
      {templates &&
        templates.map((template) => {
          return (
            <CardTemplate
              onClick={() => handleSelect(template)}
              key={template.id}
              item={template}
            />
          );
        })}
    </div>
  );
};

TemplatesItems.propTypes = {
  templates: PropTypes.array,
  handleSelect: PropTypes.func,
};

export default TemplatesItems;
