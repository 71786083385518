/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

const Footer2 = () => {
  return (
    <div>
      <footer className="py-15 py-xl-20 border-top">
        <div className="container">
          <div className="row g-4 g-lg-5 mb-10">
            <div className="col-md-3 text-center text-md-start">
              <Link to="/" className="navbar-brand">
                <img src="/dist/assets/images/logo/logo-dark.png" alt="Logo" />
              </Link>
            </div>
            <div className="col-md-4 col-lg-5 text-center text-md-start">
              <ul className="list-unstyled">
                <li className="mb-1">
                  <Link to="/terms" className="text-reset text-primary-hover">
                    Terms & conditions
                  </Link>
                </li>
                <li className="mb-1">
                  <Link to="/privacy" className="text-reset text-primary-hover">
                    Privacy policy
                  </Link>
                </li>
                <li className="mb-1">
                  <a href="/#pricing" className="text-reset text-primary-hover">
                    Pricing
                  </a>
                </li>
                <li className="mb-1">
                  <a href="/#about" className="text-reset text-primary-hover">
                    About Us
                  </a>
                </li>

                <li>
                  <a href="/blog" className="text-reset text-primary-hover">
                    Our Blog
                  </a>
                </li>
                <li>
                  <a href="/#faq" className="text-reset text-primary-hover">
                    Frequently Asked Questions
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/groups/474436847390154"
                    target="_blank"
                    rel="noreferrer"
                    className="text-reset text-primary-hover"
                  >
                    Join Our Facebook Group
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-5 col-lg-4">
              <h4>Let's connect</h4>
              <div className="grouped-inputs border p-1 rounded-pill">
                <div className="row">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control rounded-pill px-3"
                      aria-label="Text input"
                      placeholder="Enter your email to get a 7-day free trial with 5,000 words written by ResearchAI”"
                    />
                  </div>
                  <div className="col-auto">
                    <a
                      href=""
                      className="btn btn-primary btn-icon rounded-circle"
                    >
                      <i className="bi bi-arrow-return-left"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center g-1 g-lg-6 text-muted">
            <div className="col-md-6 col-lg-5 order-lg-2 text-center text-md-start"></div>
            <div className="col-md-6 col-lg-4 text-center text-md-end order-lg-3">
              <span className="small">
                1161 Mission st., San Francisco, CA 94103{" "}
              </span>
            </div>
            <div className="col-lg-3 order-lg-1 text-center text-md-start">
              <p className="small">Copyrights © 2021</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer2;
