import React, { useState, useEffect } from "react";
import axios from "axios";
import Loading from "../common/Loading";
import Header from "../layout/Header";
import Footer2 from "../layout/Footer2";

const Blog = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setLoading(true);
        const res = await axios.get("/api/documents/blogs");
        setData(res.data);
        setLoading(false);
      } catch (err) {
        console.error(err.message);
        setLoading(false);
      }
    };
    fetchBlogs();
  }, []);

  if (!loading && data) {
    return (
      <div>
        <Header />
        <section className="py-15 py-xl-20">
          <div className="container mt-5 mt-xl-10">
            <h3 className="text-primary">Blog</h3>
            <h1 className="mb-1">Learn with Research AI.</h1>
            <h5>
              Welcome to our blog. Here we share valuable content about building
              a business, growth, marketing and more.
            </h5>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active h5" aria-current="page">
                  All articles
                </li>
              </ol>
            </nav>
            <div className="row g-4 g-xl-5 justify-content-between mt-5">
              {data.map((article) => (
                <div key={article._id} className="col-md-6 col-lg-4">
                  <article className="card hover-arrow">
                    <a href={article.link}>
                      <img
                        src={article.img}
                        className="card-img-top"
                        alt="https://researchai.co"
                      />
                    </a>
                    <div className="card-body p-0 pe-lg-10 mt-2">
                      <a href={article.link} className="card-title">
                        <h5>{article.title}</h5>
                      </a>
                      <time
                        dateTime={article.date}
                        className="eyebrow text-muted"
                      >
                        Published on: {article.date.slice(0, 10)}
                      </time>
                    </div>
                  </article>
                </div>
              ))}
            </div>
          </div>
        </section>
        <Footer2 />
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default Blog;
