import React from "react";
import todoImage from "assets/images/todo.png";
import "./index.scss";

const SectionContent = () => {
  return (
    <div className="section__content">
      <div className="section__content__wrapper">
        <div className="section__content__top">
          With <span>ResearchAI</span> You Get Excellent <br />  Content Incredibly Fast! 
        </div>
        <div className="section__content__bottom">
          <div className="section__content__bottom__left">
            <div className="section__content__bottom__text">
              With ResearchAI, there is no more putting yourself through a
              torturous writing process where you waste time trying to find the
              perfect word for a sentence or trying to create paragraphs that
              are both interesting and coherent.
            </div>
            <div className="section__content__bottom__title">
              Now <span>ResearchAI</span> does all of <br /> that for you …
            </div>
            <div className="section__content__bottom__block">
              🔥 and it does it in the blink of an eye!
            </div>
          </div>
          <div className="section__content__bottom__right">
            <img src={todoImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

SectionContent.propTypes = {};

export default SectionContent;
