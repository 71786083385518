import React from "react";
import PropTypes from "prop-types";
import * as icons from "../../assets/icons/index";

const Icon = ({
  name,
  className,
  color,
  isOpen,
  style,
  progressBarData,
  backColor,
  width,
  height,
  onClick,
  color1,
  color2,
}) => {
  const IconSvg = icons[`${name}Icon`];
  if (!IconSvg) {
    return null;
  }

  return (
    <IconSvg
      className={className}
      onClick={onClick}
      color1={color1}
      color2={color2}
      color={color}
      backColor={backColor}
      isOpen={isOpen}
      style={style}
      data={progressBarData}
      width={width}
      height={height}
    />
  );
};

Icon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  color1: PropTypes.string,
  color2: PropTypes.string,
  isOpen: PropTypes.bool,
  style: PropTypes.object,
  progressBarData: PropTypes.object,
  backColor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Icon.defaultProps = {
  isOpen: false,
};

export default Icon;
