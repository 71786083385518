import React, { useState, useEffect } from "react";
import axios from "axios";
import MegaEditor from "./MegaEditor";

const Document = (props) => {
  const id = props.match.params.id;

  const [data, setData] = useState();

  useEffect(() => {
    const getDocument = async () => {
      try {
        const response = await axios.get(`/api/documents/findone/${id}`);

        setData(response.data);
      } catch (err) {
        console.error(err.message);
      }
    };
    getDocument();
  }, [id]);
  // console.log(data)

  return (
    <div className="">
      <MegaEditor data={data} id={id} />
    </div>
  );
};

export default Document;
