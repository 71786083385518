import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import document from "./document";
import error from "./error";
import template from "./template";

export default combineReducers({
  alert,
  auth,
  document,
  error,
  template,
});
