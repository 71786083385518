import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./index.scss";
import { useHistory } from "react-router";
import axios from "axios";
import SideBar from "../sections/SideBar/SideBar.jsx";
import DashboardTemplates from "./dashboardComponents/dashboardTemplates";
import { getOuputs } from "../../../utils/queris/dashboard";
import DashboardSection from "./dashboardComponents/dashboardSection";
import { setTemplate } from "../../../actions/templates";
import Loader from "../../common/Loader";
import Warning from "components/common/Warning";
import { loadUser } from "actions/auth";
import store from "store";

function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  });

  return isMounted;
}

const Dashboard = ({ auth: { user }, setTemplateClick }) => {
  const [wordsCount, setWordsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    outputsCount: 0,
    activeUseraCount: 0,
    creditUsedCount: 0,
  });
  const history = useHistory();
  const isMountedRef = useIsMounted();
  const getAllMetrics = async () => {
    return await axios.get(`api/outputs/metrics`);
  };
  useEffect(() => {
    if (isMountedRef.current) {
      setLoading(true);
      getOuputs((number) => setWordsCount(number));
      getAllMetrics()
        .then(({ data }) => {
          setData(data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    store.dispatch(loadUser());
  }, [isMountedRef]);
  const handleSelect = (item) => {
    setTemplateClick(item);
    history.push("/tools/template");
  };

  return (
    <div className="dashboard">
      {loading ? (
        <Loader />
      ) : (
        <>
          <SideBar user={user && user.name} />
          <div className="dashboard__content">
            <div className="wrapper">
              {user &&
                user.wordsLimit !== 1 &&
                user.wordsLimit - user.wordsCount < 1000 && <Warning />}
              <div className="wrapper__title">Dashboard</div>
              <DashboardTemplates
                handleSelect={handleSelect}
                wordsCount={user ? user.wordsCount : 0}
              />
              <DashboardSection />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

Dashboard.propTypes = {
  setTemplateClick: PropTypes.func,
  auth: PropTypes.object.isRequired,
};
const mapStatetoProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateClick: (template) => dispatch(setTemplate(template)),
});
export default connect(mapStatetoProps, mapDispatchToProps)(Dashboard);

/* <div>

<div className="offcanvas-wrap">
  <section className="">
    <div className="container">
      <div className="row justify-content-start">
        <div className="col-lg-3 split-sidebar">
          <div className="sticky-top">
            <SideBar user={user && user.name} />
          </div>
        </div>
        <div className="col-lg-9 split-content">
          <p className="m-3 display-4">Dashboard</p>
          <section>
            <div className="row">
              <div className="col">
                <div className="card bg-primary rounded-3 overflow-hidden">
                  <div className="card-body inverted level-3 ">
                    <div className="row g-3">
                      <div className="col-xl-8">
                        <p className="display-6">
                          Hello, {user && user.name}!
                        </p>
                      </div>
                      <div className="col-xl-12">
                        <p className="text-secondary">
                          Thank you so much for signing up to try Research
                          AI and we're glad you are here. We prepared
                          these guides to help you get started.
                        </p>
                      </div>
                    </div>
                    <div className="row g-1 align-items-center mt-6">
                      <div className="col-auto">
                        {videoData.map((d) => (
                          <iframe
                            className="col-auto p-1"
                            width="auto"
                            height="200"
                            key={d.link}
                            src={d.link}
                            title={d.title}
                            frameBorder="0"
                            allow="fullscreen;"
                          ></iframe>
                        ))}
                      </div>
                    </div>
                  </div>
                  <img
                    className="
                    position-absolute
                    top-0
                    start-100
                    translate-middle
                  "
                    src="/dist/assets/images/svg/pattern.svg"
                    alt="www"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</div>
</div> */
