import React from "react";
import HelpItem from "./HelpItem";

const Help = () => {
  return (
    <div className="help">
      <div className="help__top">
        <div className="help__top__title">
          Here’s How  <br /><span>ResearchAI</span><br />  Can Help You
        </div>
        <div className="help__top__text">
        Whether you are having difficulty writing your course content and marketing materials or are struggling to find time to turn all your course ideas into reality, ResearchAI can help. The world's most powerful deep learning algorithm fuels this next-generation tool and enables it to deliver best-in-class copywriting.
        </div>
      </div>
      <div className="help__content">
        <div className="help__content__block">
        👍  A professional copywriter
        </div>
        <HelpItem
          iconName="fileLanding"
          title="Produce course content"
          description="That is impactful and easy to understand quickly and easily."
        />
        <HelpItem
          iconName="EngagingLanding"
          title="Engaging marketing content"
          description="Generate engaging marketing content, including ads, videos, sales pages, emails, and more."
        />
        <HelpItem
          iconName="DrivenLanding"
          title="AI-driven editing tools"
          description="Polish content using AI-driven editing tools – now you don't have to worry about knowing grammar rules, ResearchAI can take care of that for you!"
        />
        <HelpItem
          iconName="StaffLanding"
          title="Free yourself and your staff"
          description="Free yourself and your staff up to work on other important tasks instead of spending so much time with course content creation."
        />
        <HelpItem
          iconName="IncreaseLanding"
          title="Increase marketing performance"
          description="Increase both student engagement and marketing performance, including conversions, while reducing costs."
        />
        <HelpItem
          iconName="DevelopLanding"
          title="Develop new ideas"
          description="ResearchAI can even help you develop new ideas to expand your course and make it even better."
        />
      </div>
    </div>
  );
};

Help.propTypes = {};

export default Help;
