
import React, {
   useRef, useMemo,
} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.scss';


const QuillEditor = ({
   text, onChange, modules, withoutBorder, placeholder
}) => {
   const formats = useMemo(() => {
      return [
         'bold',
         'italic',
         'underline',
         'strike',
         'blockquote',
         'code-block',
         'direction',
         'header',
         'font',
         'size',
         'list',
         'bullet',
         'indent',
         'ordered',
         'script',
         'link',
         'image',
         'color',
         'background',
         'align',
         'clean',
         'imageBlot',
         'width',
         'height',
         'style',
         'data-align',
         'placeholder',
         'autocomplete',
      ];
   }, []);
 
 
   const currentModules = useMemo(() => {
      return {
         toolbar: [
            [{ header: [1, 2, 3, 4, false]} ],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'align': [] }],
            ['link'],
            ['blockquote'],
         ],
         clipboard: {
            matchVisual: false,
          },
      }; 
   }, []);
 

   const editorRef = useRef(null);
   const isFirstRender = useRef(true);
 
 
   const handleChange = (value) => {
      if (isFirstRender.current) {
         isFirstRender.current = false;
         return;
      }
      onChange(value);
   };

   return (
         <div
            ref={ editorRef }
            className={
               classNames(
                  'quillEditor',
                  {
                     'quillEditor__withoutBorder': withoutBorder,
                  })
            }
         >
            <ReactQuill
               value={ text }
               onChange={ handleChange }
               theme='snow' 
               modules={ modules || currentModules }
               formats={ formats }
               placeholder={ placeholder }
            />
         </div>
   );
};
 
QuillEditor.propTypes = {
   text: PropTypes.string,
   onChange: PropTypes.func,
   withoutBorder: PropTypes.bool,
   modules: PropTypes.object,
   placeholder: PropTypes.string,
};
 
export default QuillEditor;