import React from "react";
import image from "assets/images/content.png";
import image2 from "assets/images/content2.png";
import "./index.scss";
import { useWindowSizeChange } from "utils/useWindowSizeChange";

const WorkProgress = () => {
  const windowSize = useWindowSizeChange()
  return (
    <div className="landing__progress" id='how-it-works'>
      <div className="landing__progress__wrapper">
        <div className="landing__progress__content">
          <div className="landing__progress__title">
            How Does <br /><span>ResearchAI</span><br /> Work?
          </div>
          <div className="landing__progress__description">
            Creating course content doesn’t get any easier than this! Just
            follow these three simple steps:
          </div>
        </div>
        <img src={ windowSize.innerWidth < 1024 ? image2 : image} alt="" />
      </div>
    </div>
  );
};

WorkProgress.propTypes = {};

export default WorkProgress;
