import React, { useState, useEffect } from "react";
import axios from "axios";
import NavBar from "./NavBar";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
const keyword_extractor = require("keyword-extractor");

toast.configure();
const SerpTool = () => {
  const [query, setQuery] = useState("");
  const [data, setData] = useState(
    localStorage.getItem("search")
      ? JSON.parse(localStorage.getItem("search"))
      : []
  );
  const [loading, setLoading] = useState(false);
  const [keywordArr, setKeywordArr] = useState([]);

  const handleFetch = async () => {
    if (!query.length) {
      alert("Enter title");
    } else {
      setLoading(true);

      try {
        const promise = await axios.post("/api/documents/serp", {
          query: query,
        });
        //  console.log(promise)
        const dataList = promise.data.webPages.value;
        setData(dataList);

        setLoading(false);
      } catch (err) {
        toast.error("Something went wrong. Try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        localStorage.removeItem("search");
        // window.location.reload()
      }
    }
  };

  useEffect(() => {
    localStorage.setItem("search", JSON.stringify(data));
  }, [data]);

  function renderList() {
    if (!loading && data && data.length) {
      return data.map((item) => (
        <div className="list shadow-sm border p-5 m-5">
          <h4>{item.name}</h4>
          <a href={item.url}>{item.url}</a>
          <p>{item.snippet}</p>
        </div>
      ));
    }
  }

  useEffect(() => {
    const exctractKeywords = () => {
      let arrayOfString = [];
      for (let item of data ? data.slice(0, 5) : []) {
        arrayOfString.push(item.snippet);
      }
      const newString = arrayOfString.toString();
      const extraction_result = keyword_extractor.extract(newString, {
        language: "english",
        remove_digits: false,
        return_changed_case: true,
        remove_duplicates: true,
        return_chained_words: true,
      });

      setKeywordArr(extraction_result);
    };
    exctractKeywords();
  }, [data]);

  return (
    <>
      <NavBar />
      <div className="search-wrapper container">
        <div className="flex-old p-10">
          <h2 className="space-top-1">SERP Tool</h2>
          <p>
            Get keyword ideas and analyze how your competitors are ranking on
            Search Engines.
          </p>
          <h6>Note: You can do a maximum of 5 requests per day.</h6>
          <div className="grouped-inputs p-1 rounded-pill border mt-10">
            <div className="row g-0">
              <div className="col">
                <input
                  className="form-control"
                  placeholder="enter blog/article title"
                  type="text"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
              <div className="col-auto d-grid">
                <button
                  onClick={() => handleFetch()}
                  className="btn btn-primary btn-m btn-icon rounded-circle"
                >
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </div>
          </div>

          <div className=" mt-10">
            {data && data.length ? (
              <h3 className="">Extracted keywords</h3>
            ) : (
              ""
            )}

            {keywordArr.map((item) => (
              <span className="badge rounded-pill m-1 bg-light text-dark">
                {item}
              </span>
            ))}
          </div>
          <hr className="mt-50 mb-50" />
          {data && data.length ? (
            <h3>Search Engine Results Page (SERP)</h3>
          ) : (
            ""
          )}

          {renderList()}
        </div>
      </div>
    </>
  );
};

export default SerpTool;
