import React from "react";
import Hero from "../layout/Hero/index";
import Header from "../layout/Header";
import Faq2 from "../layout/Faq/index";
import Footer2 from "../layout/Footer/index";
import Story from "../layout/Story";
import Comments from "../layout/Comments";
import Ideal from "../layout/SectionIdeal";
import SectionContent from "components/layout/SectionContent";
import WorkProgress from "components/layout/WorkProgress";
import Help from "components/layout/Help";
import Services from "components/layout/Services";
import Steps from "components/layout/Steps";
import PricingFAQ from 'components/pages/Pricing/PricingComponents/PricingFAQ'

const Landing = () => {
  return (
    <div>
      <Header />
      <div className="offcanvas-wrap">
        <Hero />
        {/* <Customers /> */}
        {/* <Feature4 /> */}
        {/* <Features3 /> */}
        {/* <Tabs /> */}
        {/* <Target /> */}
        {/* <SerpFeature2 /> */}
        {/* <Testimonial /> */}
        {/* <Pricing2 /> */}
        {/* <Cta /> */}
        <Services />
        <Help />
        <Steps />
        <WorkProgress />
        <SectionContent />
        <Ideal />
        <Comments />
        <Story />
        {/* <Faq2 /> */}
        <PricingFAQ />
        <Footer2 />
      </div>
    </div>
  );
};

export default Landing;
