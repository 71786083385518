import React, { useState } from "react";
import "./index.scss";
import { useHistory } from "react-router-dom";

const ConnectFormInput = ({ name, value, onChange, placeholder }) => {
  return (
    <input
      className="connect__form__input"
      name={name}
      onChange={(e) => onChange(e.target.name, e.target.value)}
      value={value}
      placeholder={placeholder}
    />
  );
};

const ConnectForm = ({}) => {
  const history = useHistory();
  const [formState, setFormState] = useState({
    name: "",
    email: "",
  });

  const onSumbit = () => {
    history.push("/register", formState);
  };

  return (
    <div className="connect__form__wrapper">
      <div className="connect__form">
        <div className="connect__circle circle-1"></div>
        <div className="connect__circle circle-2"></div>
        <div className="connect__form__title">Let’s Connect</div>
        <div className="connect__form__subtitle">
          Enter Your Name & Email To Get Free Access To Researchai
        </div>
        <div className="connect__form__inputs">
          <ConnectFormInput
            name="name"
            value={formState.name}
            placeholder="Enter your name"
            onChange={(name, value) =>
              setFormState({ ...formState, [name]: value })
            }
          />
          <ConnectFormInput
            name="email"
            value={formState.email}
            placeholder="Enter your email"
            onChange={(name, value) =>
              setFormState({ ...formState, [name]: value })
            }
          />
        </div>
        <button className="connect__form__button" onClick={() => onSumbit()}>
          Sign Up Free
        </button>
      </div>
      <div className="connect__form__icon">😻</div>
    </div>
  );
};

ConnectForm.propTypes = {};

export default ConnectForm;
