import { element } from "prop-types";
import React, { useEffect, useRef } from "react";
import "./index.scss";

const variants = ["first", "second", "third", "fourty"];

const LandingSliderLeft = ({ selectedVariant, setSelectedVariant }) => {
  const firstRef = useRef()
  const secondRef = useRef()
  const thirdRef = useRef()
  const fourthRef = useRef()
  const getPositionVariant = (variant) => {
    if (variants.indexOf(variant) < variants.indexOf(selectedVariant)) {
      return "variant__active";
    }
    return "variant";
  };
//   const scrollHandler = () => {
//     if(window.pageYOffset + window.innerHeight + 50 >= firstRef.current.offsetTop){
//       setSelectedVariant('first')
//     }
//     if(window.pageYOffset + window.innerHeight+ 50 >= secondRef.current.offsetTop){
//       setSelectedVariant('second')
//     }
//     if(window.pageYOffset + window.innerHeight+ 50 >= thirdRef.current.offsetTop){
//       setSelectedVariant('third')
//     }
//     if(window.pageYOffset + window.innerHeight+ 50 >= fourthRef.current.offsetTop){
//       setSelectedVariant('fourty')
//     }
// }
//   useEffect(() => {
//     window.addEventListener('scroll', scrollHandler);
//     return () => window.removeEventListener('scroll', scrollHandler);
// }, []);
  return (
    <div className="variant__flex" 
    >
      <div
        className={`${
          selectedVariant === "first"
            ? "variant__selected"
            : getPositionVariant("first")
        }`}
        role="presentation"
        ref={firstRef}
        onClick={() => setSelectedVariant("first")}
      >
        <div className="variant__dot__circle"></div>
        <div className="variant__dot"></div>
        <div className="variant__right">
          <div className="variant__text">Research briefs</div>
          <div className="variant__description">
          That you can use to ensure articles are detailed and well-researched!
          </div>
        </div>
      </div>
      <div
        className={`${
          selectedVariant === "second"
            ? "variant__selected"
            : getPositionVariant("second")
        }`}
        ref={secondRef}
        role="presentation"
        onClick={() => setSelectedVariant("second")}
      >
        <div className="variant__dot__circle"></div>
        <div className="variant__dot"></div>
        <div className="variant__right">
          <div className="variant__text">AI templates</div>
          <div className="variant__description">
            That guide you in creating engaging content that will hook your
            audience – there are templates for: Articles, Facebook ads, Google
            ads, Marketing emails, Sales letters that follow proven effective
            formulas like AIDA (Attention, Interest, Desire, Action) and PAS
            (Pain, Agitate, Solution), Bios, Video scripts, Social media posts,
            Website pages and more.
          </div>
        </div>
      </div>
      <div
        className={`${
          selectedVariant === "third"
            ? "variant__selected"
            : getPositionVariant("third")
        }`}
        ref={thirdRef}
        role="presentation"
        onClick={() => setSelectedVariant("third")}
      >
        <div className="variant__dot__circle"></div>
        <div className="variant__dot"></div>
        <div className="variant__right">
          <div className="variant__text">Keyword research</div>
          <div className="variant__description">
            Get keyword ideas for your marketing and determine how your
            competitors rank on the search engines!
          </div>
        </div>
      </div>
      <div
        className={`${
          selectedVariant === "fourty"
            ? "variant__selected variant__last"
            : `${getPositionVariant("fourty")} variant__last`
        }`}
        role="presentation"
        ref={fourthRef}
        onClick={() => setSelectedVariant("fourty")}
      >
        <div className="variant__dot__circle"></div>
        <div className="variant__dot"></div>
        <div className="variant____right">
          <div className="variant__text">And much more</div>
          <div className="variant__description">
            The platform is easy to use and navigate. I like that you can
            generate each section step-by-step with ease. The integration of
            SEMrush is a big win for Research AI!
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingSliderLeft;
