import React from "react";
import Icon from "../Icon";
import "./index.scss";
import PropTypes from "prop-types";

const CardTemplate = ({ item, onClick }) => {
  return (
    <div className="card" role="presentation" onClick={onClick}>
      <div className="card__top">
        <div className="card__icon">
          <Icon name={item.icon} />
        </div>
        {item.new && <span className="card__new">New</span>}
      </div>
      <div className="card__bottom">
        <div className="card__title">{item.title}</div>
        <div className="card__description">{item.description}</div>
      </div>
    </div>
  );
};

CardTemplate.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
};

export default CardTemplate;
