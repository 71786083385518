export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const USER_LOADED = "USER_LOADED";
export const INVITED__USER__LOADED = "IVITED__USER__LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const UPDATE_MEMBER = "UPDATE_MEMBER"
export const LOGOUT = "LOGOUT";
// DOC
export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_DOCUMENT = "GET_DOCUMENT";
export const SAVE_DOCUMENT = "SAVE_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const DOCUMENT_LOADING = "DOCUMENT_LOADING";
export const GET_ERRORS = "GET_ERRORS";
export const SET_TEMPLATE = "SET_TEMPLATE";
export const CLEAR_TEMPLATE = "CLEAR_TEMPLATE";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const UPDATE_USER = "UPDATE_USER";
export const USER_LOAD_END = 'USER_LOAD_END';
export const USER_LOAD_START = 'USER_LOAD_START';
export const SET_DOCUMENT_ID = 'SET_DOCUMENT_ID'; 

export const SIGNUP_START = 'SIGNUP_START'