import React from "react";
import "./index.scss";
import PropTypes from "prop-types";
import Icon from "../Icon";
import { StyledTooltip } from "../../private/sections/SideBar/SideBar";
import { IconButton } from "@mui/material";

const TextArea = ({
  value,
  maxHeight,
  label,
  placeholder,
  name,
  tooltipText,
  onChange,
}) => {
  return (
    <div className="input">
      <div className="input__top">
        {label && <div className="input__top__label">{label}</div>}
        {tooltipText && (
          <div className="input__top__tooltip" data-tip={tooltipText}>
            <StyledTooltip componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: 'common.black',
                          '& .MuiTooltip-arrow': {
                            color: 'common.black',
                          },
                        },
                      },
                    }}  title={tooltipText} component={'span'} placement="right">
              <IconButton>
                <Icon name="Tooltip" />
              </IconButton>
            </StyledTooltip>
          </div>
        )}
      </div>
      <textarea
        name={name}
        value={value || ""}
        style={{ minHeight: `${maxHeight}px` }}
        placeholder={placeholder}
        onChange={({ target }) => onChange(target.name, target.value)}
      />
    </div>
  );
};

TextArea.propTypes = {
  name: PropTypes.any,
  label: PropTypes.any,
  placeholder: PropTypes.any,
  value: PropTypes.any,
  optional: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  maxHeight: PropTypes.number,
  tooltipText: PropTypes.any,
};

export default TextArea;
