import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Icon from "../../../../common/Icon";
import Loader from "../../../../common/Loader";
import "./index.scss";
import { Master, Visa } from "./cards";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useElements,
} from "@stripe/react-stripe-js";
import Modal from "../../../../common/Modal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { DeleteModalContent, EditModal } from "../Modals";
//pk_live_gXu1JpGxSnMivsPDa4lfksRG00kaqSQ7s8+
const promise = loadStripe(
  `${process.env.REACT_APP_stripeSecretKeyP}`,
  {
    locale: "en",
  }
);

export const inputStyle = {
  fontWeight: "400",
  fontSize: "14px",
  color: "#2F2F2F",
  lineHeight: "20px",
};
const StripeCard = ({ user, setCardList, closeModal, setLoader }) => {
  const stripe = useStripe();
  const elements = useElements();
  const handleSumbit = async (e) => {
    setLoader(true);
    e.preventDefault();
    // setLoader(true);
    let { email } = user;
    const { paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: {
        email: email,
      },
    });
    closeModal();
    if (paymentMethod) {
      try {
        const { data } = await axios.post("/api/payments/add", {
          id: paymentMethod.id,
          userId: user._id,
        });
        setCardList(data.cards);
        setLoader(false);
        toast.success("Your card has been added");
      } catch ({ response }) {
        setLoader(false);
        toast.error(response.data.raw.message);
      }
    } else {
      setLoader(false);
      toast.error("There was an issue with your payment! Try again.");
    }
  };
  return (
    <form onSubmit={(e) => handleSumbit(e)}>
      <div className="stripe__card">
        <div className="strip__card__number">
          <div className="label">Card Number</div>
          <div className="stripe__card__input">
            <CardNumberElement
              options={{
                style: {
                  base: inputStyle,
                },
              }}
            />
          </div>
        </div>
        <div className="stripe__card__bottom">
          <div className="stripe__card__date">
            <div className="label">Expiration date</div>
            <div className="stripe__card__input">
              <CardExpiryElement
                options={{
                  style: {
                    base: inputStyle,
                  },
                }}
              />
            </div>
          </div>
          <div className="stripe__card__cvc">
            <div className="label">CVC</div>
            <div className="stripe__card__input">
              <CardCvcElement
                options={{
                  style: {
                    base: inputStyle,
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <button type="submit" className="btn-main add__card__button">
        Add card
      </button>
    </form>
  );
};

const Payments = ({ userId, user, cardList, setCardList }) => {
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenModal, SetIsOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [deleteModalContent, setDeleteModalContent] = useState({
    isOpen: false,
  });

  const OnSelectNewDefaultCard = async (card) => {
    setLoader(true);
    try {
      const { data } = await axios.post(`/api/payments/default`, {
        userId: userId,
        paymentId: card.paymentId,
      });
      setLoader(false);
      if (data.isActivated) {
        setCardList(data.newData);
        toast.success("Default card has been changed");
      }
    } catch ({ response }) {
      setLoader(false);
      toast.error(response.data.raw.message);
    }
  };

  const onDelete = (card) => {
    setIsOpenEditModal(false);
    setDeleteModalContent({
      card,
      isOpen: true,
    });
  };

  const onDetachPaymentMethod = async (card) => {
    setDeleteModalContent({ isOpen: false });
    setLoader(true);
    try {
      const { data } = await axios.post(`/api/payments/remove`, {
        paymentId: card.paymentId,
        userId,
      });
      setLoader(false);
      if (data.isRemoved) {
        setCardList(data.newData);
        setIsOpenEditModal(true);
        toast.success("Your card has been deleted");
      }
    } catch ({ response }) {
      setLoader(false);
      toast.error(response.data.raw.message);
    }
  };

  useEffect(() => {
    if (userId) {
    }
  }, [userId]);

  return (
    <>
      <div className="payments">
        {loader && <Loader />}
        <div className="payments__top">
          <div className="payments__top__left">
            <div className="payments__top__title">Payment method</div>
            <div className="payments__top__subtitle">Your payment cards</div>
          </div>
          {deleteModalContent.isOpen && (
            <Modal
              onClose={() => {
                setDeleteModalContent({ isOpen: false });
                setIsOpenEditModal(true);
              }}
              hasCloseButton={false}
            >
              <DeleteModalContent
                last4={deleteModalContent.card.last4}
                onDelete={() => onDetachPaymentMethod(deleteModalContent.card)}
                onClose={() => {
                  setDeleteModalContent({ isOpen: false });
                  setIsOpenEditModal(true);
                }}
              />
            </Modal>
          )}
          {isOpenEditModal && (
            <Modal onClose={() => setIsOpenEditModal(false)}>
              <EditModal
                cards={cardList}
                onDelete={onDelete}
                onSelect={OnSelectNewDefaultCard}
              />
            </Modal>
          )}
          {!!cardList.length && (
            <div
              className="payments__top__right"
              role="presentation"
              onClick={() => setIsOpenEditModal(true)}
            >
              <Icon name="Edit" />
              Edit Payment Method
            </div>
          )}
        </div>
        <div className="card__list">
          {!!cardList.length &&
            cardList.map((card) => {
              if (card.brand === "visa") {
                return <Visa card={card} key={uuidv4()} />;
              } else if (card.brand === "mastercard") {
                return <Master card={card} key={uuidv4()} />;
              } else {
                return <Visa card={card} key={uuidv4()} iconName={card.brand} />;
              }
            })}
          <div
            className="card__list__add"
            role="presentation"
            onClick={() => SetIsOpenModal(true)}
          >
            <Icon name="Add" />
            Add payment method
          </div>
          {isOpenModal && (
            <Modal onClose={() => SetIsOpenModal(false)}>
              <div className="add__card__title">Add a new payment method</div>
              <div className="add__card__subtitle">
                Enter your payment card details
              </div>
              <Elements stripe={promise}>
                <StripeCard
                  closeModal={() => SetIsOpenModal(false)}
                  user={user}
                  setLoader={setLoader}
                  setCardList={(cards) => {
                    setCardList(cards);
                  }}
                />
              </Elements>
            </Modal>
          )}
        </div>
      </div>
    </>
  );
};

Payments.propTypes = {
  userId: PropTypes.string,
};

export default Payments;
