import React, { useState } from "react";
import "./index.scss";
import Icon from "../Icon";

const lan = [
  "English",
  "Italian",
  "Chinese",
  "Estonian",
  "Latvian",
  "French",
  "Dutch",
  "Portuguese",
  "Finish",
  "Romanian",
  "Spanish",
  "Russian",
  "Bulgarian",
  "Hungarian",
  "Slovak",
  "Polish",
  "Czech",
  "Japanese",
  "Slovenian",
  "German",
  "Danish",
  "Greek",
  "Lithuanian",
  "Swedish",
];

const FacItem = ({ title, optionText, isLanguageSection = false }) => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <div className="fac__item" onClick={()=>setIsOpened(!isOpened)}>
      <div className="fac__item__wrapper">
        <div className="fac__item__left">
          <div className="title">{title}</div>
          {isOpened && (
            <>
              <div className="option">{optionText}</div>
              {isLanguageSection && (
                <div className="languages">
                  {lan.map((a) => {
                    return (
                      <div className="language" key={a}>
                        <Icon name="PricingLanguage" />
                        {a}
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          )}
        </div>
        <div
          className="fac__item__right"
          role="presentation"
          style={{ transform: !isOpened ? "rotate(0deg)" : "rotate(45deg)" }}
          onClick={() => setIsOpened(!isOpened)}
        >
          <Icon name="CloseFAQ" />
        </div>
      </div>
    </div>
  );
};

export default FacItem;
