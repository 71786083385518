/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Loading from "../../../common/Loading";
import axios from "axios";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

const Step3 = ({ func, blogTitle, startFunc, previousStep, outline }) => {
  const [intro, setIntro] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    func(intro);
  }, [intro]);

  const handleSubmit = async (e) => {
    // e.preventDefault()
    let prompt = `Given the blog post title and an outline, write an introduction for the blog post.
     Title: ${blogTitle}:
     Outline: ${outline}
     Introduction:`;

    try {
      setLoading(true);
      const promise = await axios.post("/api/index/beastmode", {
        text: prompt,
        token: 135,
        runs: 2,
      });

      const list = promise.data["choices"];
      setData(list);
      setLoading(false);
    } catch (err) {
      toast.error("Something went wrong. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
    }
  };

  const handleSelect = (text) => {
    setIntro(text);
  };

  return (
    <div>
      <div className="d-flex flex-row">
        <h3 className="fs-3 mt-10 text-primary"> Step 4.</h3>
      </div>
      <h4>Write an introduction for your content.</h4>

      <div className="form-group mb-2">
        <label htmlFor="productDesc">
          <h6 className="font-weight-bold mb-2">
            <span className="text-secondary">
              Write your own intro below or let AI create one for you.
            </span>
          </h6>
        </label>
        <textarea
          className="form-control shadow-sm"
          rows="4"
          name="intro"
          value={intro}
          onChange={(e) => setIntro(e.target.value)}
          placeholder="Your article introductory paragraph"
        ></textarea>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <div className="contollers d-flex flex-row">
          <div className="form-group mb-5">
            <button
              style={{ marginRight: "10px" }}
              onClick={() => previousStep()}
              className="btn bg-secondary inverted  shadow mt-5"
            >
              Previous Step
            </button>
          </div>
          <div className="form-group mb-5">
            <button
              onClick={() => startFunc()}
              className="btn bg-primary inverted  shadow mt-5"
            >
              Launch Editor
            </button>
          </div>
        </div>

        <div className="form-group mb-5">
          <button
            onClick={() => handleSubmit()}
            className="btn bg-black inverted  shadow mt-5"
          >
            Generate Introduction
          </button>
        </div>
      </div>
      <div className="d-flex flex-column justify-content-center mb-15">
        {loading ? (
          <Loading />
        ) : (
          data.map((item, id) => (
            <div className="mb-2">
              <p className={`shadow-sm p-2`} key={id}>
                {item.text}{" "}
              </p>
              <span
                className="btn btn-sm btn-light mb-2"
                style={{ cursor: "pointer" }}
                onClick={() => handleSelect(item.text)}
              >
                Select
              </span>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Step3;
