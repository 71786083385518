import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SideBar from "./sections/SideBar/SideBar.jsx";
import VideoToolForm from "./sections/tools/VideoToolForm";

const ToolSet = ({ auth: { user } }) => {
  return (
    <div>
      <div className="container space-1 space-bottom-lg-3 ">
        <div className="row">
          <div className="col-lg-3 mb-5 mb-lg-0">
            <div className="mr-lg-3">
              <SideBar user={user && user.name} />
            </div>
          </div>
          <div className="col-lg-9 column-divider-lg">
            <VideoToolForm />
            <div className="ml-lg-2">
              <div className="row container mx-n2 mb-7"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ToolSet.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStatetoProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStatetoProps)(ToolSet);
