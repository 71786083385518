/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

const Step1 = ({ func, nextStep }) => {
  const [formData, setFormData] = useState({
    keyword: "",
    description: "",
  });

  const { keyword, description } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    func(keyword, description);
  }, [description, keyword]);
  return (
    <div>
      <div className="d-flex flex-row">
        <h3 className="fs-3 mt-10 text-primary"> Step 1.</h3>
      </div>
      <h4>Describe your content.</h4>

      <div className="form-group mb-2">
        <label htmlFor="productDesc">
          <h6 className="font-weight-bold mb-2">
            <span className="text-secondary">
              Our AI generates results based on description you provide. That's
              why try to describe what you are writing as better as possible to
              get higher quality output.
            </span>
          </h6>
        </label>
        <textarea
          className="form-control shadow-sm"
          id=""
          rows="3"
          name="description"
          value={description}
          onChange={handleChange}
          placeholder="e.g. 3 reasons why should you use ai copywriting software Research AI. Talk about
            advantages and disadvantages of using ai..."
        ></textarea>
      </div>
      <div className="form-group mb-2">
        <label htmlFor="productName">
          <h6 className="font-weight-bold ">
            Keywords <span className="text-secondary">(Optional)</span>
          </h6>{" "}
        </label>
        <p>Maximum of 4.</p>
        <input
          type="text"
          className="form-control shadow-sm "
          name="keyword"
          value={keyword}
          onChange={handleChange}
          placeholder="e.g. content marketing, copywriting, copywriter tools"
        />
      </div>
      <div className="form-group mb-5">
        <button
          onClick={() => nextStep()}
          className="btn bg-primary inverted  shadow mt-5"
        >
          Next Step
        </button>
      </div>
    </div>
  );
};

export default Step1;
