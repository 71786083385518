/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import Icon from "../../../../common/Icon";
import { toast } from "react-toastify";
import axios from "axios";
import { useHistory } from "react-router";
import {
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  useElements,
} from "@stripe/react-stripe-js";
import { getcardDate } from "../Payments/cards";
import { loadStripe } from "@stripe/stripe-js";
import Loader from "../../../../common/Loader";
import { inputStyle } from "../Payments";

export const EditModal = ({ cards, onSelect, onDelete }) => {
  return (
    <div className="edit__cards">
      <div className="edit__cards__top">
        <div className="edit__cards__title">Edit Payment Method</div>
        <div className="edit__cards__subtitle">List of your added cards</div>
      </div>
      <div className="cards">
        {cards &&
          cards.map((card) => {
            return (
              <div className="edit__card" key={uuidv4()}>
                <div className="edit__card__right">
                  {card.isDefault ? (
                    <div className="edit__cards__selected"></div>
                  ) : (
                    <div
                      className="edit__cards__unselected"
                      role="presentation"
                      onClick={() => onSelect(card)}
                    ></div>
                  )}
                  {card.brand === "mastercard" && <Icon name="Master" />}
                  {card.brand !== "mastercard" && card.brand !== "visa" && (
                    <Icon name={card.brand} />
                  )}
                  {card.brand === "visa" && (
                    <Icon name="Visa" color1="#F9B50B" color2="#3362AB" />
                  )}
                  <div className="edit__card__number">**** {card.last4}</div>
                  <div className="edit__card__date">
                    {getcardDate(card.exp_year)}
                  </div>
                </div>
                {!card.isDefault && (
                  <div
                    className="edit__card__trash"
                    role="presentation"
                    onClick={() => onDelete(card)}
                  >
                    <Icon name="Trash" />
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const DeleteModalContent = ({ onClose, onDelete, last4 }) => {
  return (
    <div className="delete__modal">
      <div className="delete__modal__top">
        <div className="delete__modal__title">
          Are you sure you want to delete the **** {last4} card ?
        </div>
        <div className="delete__modal__subtitle">
          This action may result in the loss of a subscription if performed from
          this card
        </div>
      </div>
      <div className="delete__modal__footer">
        <button className="btn-second" onClick={() => onClose()}>
          Cancel
        </button>
        <button className="btn-main" onClick={() => onDelete()}>
          Delete
        </button>
      </div>
    </div>
  );
};
DeleteModalContent.propTypes = {
  last4: PropTypes.string,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
};

const promise = loadStripe(`${process.env.REACT_APP_stripeSecretKeyP}`, {
  locale: "en",
});

const StripeCard = ({ user, plan, setLoading }) => {
  const stripe = useStripe();
  const elements = useElements();
  let history = useHistory();
  const handleSubmitSub = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    let { email } = user;
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: {
        email: email,
      },
    });
    if (result.error) {
      toast.error("There was an issue with your payment! Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setLoading(true);
      try {
        const res = await axios.post("/api/payments/subscribe", {
          payment_method: result.paymentMethod.id,
          email: email,
          plan: plan.code,
        });
        const { customerId, planId, subId } = res.data;

        if (customerId.length === 0) {
          toast.error("There was an issue with your payment card! Try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          // Need new Implementation here
          try {
            const config = {
              headers: {
                "Content-Type": "application/json",
              },
            };

            const body = JSON.stringify({
              email,
              customerId,
              planId,
              subId,
              wordsLimit: plan.wordsLimit,
            });
            await axios.patch("/api/users/upgrade", body, config);
            history.push("/dashboard");
            localStorage.setItem("warning", "on")
            toast.success("You have successfully upgraded your plan.");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } catch ({ response }) {
            toast.error(response.data);
          }
        }
      } catch ({ response }) {
        toast.error(response.data.raw.message);
      }
      setLoading(false);
      // eslint-disable-next-line camelcase
      // const {client_secret, status} = res.data;
    }
  };
  return (
    <form onSubmit={(e) => handleSubmitSub(e)}>
      <div className="stripe__card">
        <div className="strip__card__number">
          <div className="label">Card Number</div>
          <div className="stripe__card__input">
            <CardNumberElement
              options={{
                style: {
                  base: inputStyle,
                },
              }}
            />
          </div>
        </div>
        <div className="stripe__card__bottom">
          <div className="stripe__card__date">
            <div className="label">Expiration date</div>
            <div className="stripe__card__input">
              <CardExpiryElement
                options={{
                  style: {
                    base: inputStyle,
                  },
                }}
              />
            </div>
          </div>
          <div className="stripe__card__cvc">
            <div className="label">CVC</div>
            <div className="stripe__card__input">
              <CardCvcElement
                options={{
                  style: {
                    base: inputStyle,
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <button type="submit" className="btn-main add__card__button">
        Connect payment plan
      </button>
    </form>
  );
};

export const AddPlanContent = ({
  user,
  plan,
  cards,
  onSumbit,
  isConnected,
  isFreePlan,
}) => {
  const [selectedPayment, setSelectedPayment] = useState(
    cards.filter((card) => card.isDefault)[0]
  );
  const [loading, setLoading] = useState(false);
  const [cardsMap, setCardsMap] = useState(
    cards ? cards.filter((card) => !card.isDefault) : []
  );
  const word = isConnected ? "Update" : "Connect";
  useEffect(() => {
    setCardsMap(
      cards.filter((item) => item.paymentId !== selectedPayment.paymentId)
    );
  }, [selectedPayment]);
  const [isOpenCardSelector, setIsOpenCardSelector] = useState(false);
  return (
    <div className="plan__modal">
      {loading && <Loader />}
      <div className="plan__modal__toop">
        <div className="plan__modal__title">Plan {word}</div>
        <div className="plan__modal__subtitle">
          You are going to {isConnected ? "update" : "connect"} your payment
          plan
        </div>
      </div>
      <div className="plan__modal__details">
        <div className="details">Plan Details</div>
        <div className="plan__modal__details__flex">
          <div className="plan__modal__details__name">{plan.name}</div>
          <div className="plan__modal__details__limit">
            {plan.limit} amount of words
          </div>
          <div className="plan__modal__details__price">
            {plan.price} <span>/month</span>
          </div>
        </div>
      </div>
      <div className="plan__modal__diver"></div>
      {plan.name !== "Free plan" && (
        <>
          {isConnected ? (
            <div className="plan__modal__payment">
              <div className="details">Payment Details</div>
              <div className="plan__modal__payment__top">
                <div className="plan__modal__payment__selected">
                  {selectedPayment &&
                    `${selectedPayment.brand} **** ${selectedPayment.last4}`}
                </div>
                {cards.length > 1 && (
                  <div
                    className={`plan__modal__payment__change ${
                      !isOpenCardSelector &&
                      "plan__modal__payment__change__selected"
                    }`}
                    role="presentation"
                    onClick={() => setIsOpenCardSelector(!isOpenCardSelector)}
                  >
                    <Icon name="Edit" />
                    Change Card
                  </div>
                )}
              </div>
              {isOpenCardSelector && (
                <div className="plan__modal__hiden__select">
                  {cardsMap.map((card) => {
                    return (
                      <div
                        className="select__item"
                        key={uuidv4()}
                        role="presentation"
                        onClick={() => {
                          setSelectedPayment(card);
                          setIsOpenCardSelector(false);
                        }}
                      >
                        <div className="item__left">
                          <div className="cirlcle"></div>
                          <div className="item__number">**** {card.last4}</div>
                          <div className="item__date">
                            {getcardDate(card.exp_year)}
                          </div>
                        </div>
                        {card.brand === "mastercard" && <Icon name="Master" />}
                        {card.brand !== "mastercard" &&
                          card.brand !== "visa" && <Icon name={card.brand} />}
                        {card.brand === "visa" && (
                          <Icon name="Visa" color1="#F9B50B" color2="#3362AB" />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          ) : (
            <div>
              <Elements stripe={promise}>
                <StripeCard user={user} plan={plan} setLoading={setLoading} />
              </Elements>
            </div>
          )}
        </>
      )}
      {isFreePlan && !cards.length && plan.name !== "Free plan" && (
        <Elements stripe={promise}>
          <StripeCard user={user} plan={plan} setLoading={setLoading} />
        </Elements>
      )}
      {plan.name === "Free plan" && (
        <button className="btn-main" onClick={() => onSumbit(plan, "FREE")}>
          {word} payment plan
        </button>
      )}
      {isConnected && plan.name !== "Free plan" && !(isFreePlan && !cards.length) && (
        <button
          className="btn-main"
          onClick={() => onSumbit(plan, selectedPayment.paymentId)}
        >
          {word} payment plan
        </button>
      )}
    </div>
  );
};

export const CancelModal = ({ onClose, onSumbit }) => {
  return (
    <div className="delete__modal">
      <div className="delete__modal__top">
        <div className="delete__modal__title">
          Are you sure you want to cancel?
        </div>
        <div className="delete__modal__subtitle">
          We value our customers and always ready to help. Please contact us if
          you need help with anything.
        </div>
      </div>
      <div className="delete__modal__footer">
        <button className="btn-second" onClick={() => onClose()}>
          Close
        </button>
        <button className="btn-main" onClick={() => onSumbit()}>
          Yes, cancel
        </button>
      </div>
    </div>
  );
};

AddPlanContent.propTypes = {
  plan: PropTypes.object,
  cards: PropTypes.array,
  onSumbit: PropTypes.func,
};
CancelModal.propTypes = {
  onClose: PropTypes.func,
  onSumbit: PropTypes.func,
};
