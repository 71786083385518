import React from "react";
import "./index.scss";
import PropTypes from "prop-types";
import Icon from "../Icon";
import { v4 as uuidv4 } from "uuid";
import { StyledTooltip } from "../../private/sections/SideBar/SideBar";
import { IconButton } from "@mui/material";

const SelectValue = ({
  value,
  label,
  name,
  options = [],
  onChange,
  tooltipText,
}) => {
  return (
    <div className="input">
      <div className="input__top">
        {label && <div className="input__top__label">{label}</div>}
        {tooltipText && (
          <div className="input__top__tooltip" data-tip={tooltipText}>
            <StyledTooltip  componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: 'common.black',
                          '& .MuiTooltip-arrow': {
                            color: 'common.black',
                          },
                        },
                      },
                    }} title={tooltipText} placement="right">
              <IconButton>
                <Icon name="Tooltip" />
              </IconButton>
            </StyledTooltip>
          </div>
        )}
      </div>
      <div className="inputs__options">
        {options.map((option) => {
          return (
            <div
              key={uuidv4()}
              role="presentation"
              onClick={() => onChange(name, option)}
              className={`inputs__options__item ${
                option === value ? "inputs__options__item__active" : ""
              }`}
            >
              {option}
            </div>
          );
        })}
      </div>
    </div>
  );
};

SelectValue.propTypes = {
  name: PropTypes.any,
  label: PropTypes.any,
  tooltipText: PropTypes.any,
  value: PropTypes.any,
  options: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

export default SelectValue;
