import React, { useState } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { useDetectClickOutside } from "react-detect-click-outside";
import Icon from "../Icon";

const Input = ({
  value,
  label,
  iconName,
  disabled,
  placeholder,
  name,
  optional,
  isHaveInviteButton = false,onInvite,
  onChange,
  type,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const ref = useDetectClickOutside({ onTriggered: () => setIsFocused(false) });
  if (type === "password") {
    return (
      <div className="input">
        <div className="input__top">
          {label && <div className="input__top__label">{label}</div>}
          {optional && <div className="input__top__optional">({optional})</div>}
        </div>
        <div
          ref={ref}
          className={`input__div ${isFocused && `input__div__focused`}`}
        >
          <input
            name={name}
            disabled={ !!disabled }
            onFocus={() => setIsFocused(true)}
            value={value || ""}
            placeholder={placeholder}
            onChange={({ target }) => onChange(target.name, target.value.trim())}
            type={!isShow ? "password" : "text"}
          />
          {!isShow ? (
            <div role="presentation" onClick={() => setIsShow(true)}>
              <Icon name="Show" />
            </div>
          ) : (
            <div role="presentation" onClick={() => setIsShow(false)}>
              <Icon name="Hide" />
            </div>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className="input">
      <div className="input__top">
        {label && <div className="input__top__label">{label}</div>}
        {optional && <div className="input__top__optional">({optional})</div>}
      </div>
      {isHaveInviteButton ? (
        <div className="input__flex">
         <div
        ref={ref}
        className={`input__div ${isFocused && `input__div__focused`}`}
      >
        {iconName && <Icon name={iconName} />}
        <input
          name={name}
          onFocus={() => setIsFocused(true)}
          value={value || ""}
          disabled={ !!disabled }
          placeholder={placeholder}
          onChange={({ target }) => onChange(target.name, target.value.trim())}
          type="text"
        />
      </div>
      <button className='btn-main' onClick={()=>onInvite()}>Send Invite</button>
        </div>
      ):(
        <div
       ref={ref}
       className={`input__div ${isFocused && `input__div__focused`}`}
     >
       {iconName && <Icon name={iconName} />}
       <input
         name={name}
         onFocus={() => setIsFocused(true)}
         value={value || ""}
         disabled={ !!disabled }
         placeholder={placeholder}
         onChange={({ target }) => onChange(target.name, target.value )}
         type="text"
       />
     </div>
      )}
     
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.any,
  iconName: PropTypes.string,
  label: PropTypes.any,
  disabled: PropTypes.bool,
  placeholder: PropTypes.any,
  value: PropTypes.any,
  optional: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  onInvite: PropTypes.func,
  isHaveInviteButton: PropTypes.bool,
};

export default Input;
