/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import axios from "axios";

const MegaHeader = ({ count, title, id, tool, switchFunc }) => {
  let history = useHistory();

  const [name, setName] = useState(title);
  const [share, setShare] = useState("share");

  useEffect(() => {
    const handleSave = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const body = JSON.stringify({ title: name });
        await axios.patch(`/api/documents/findone/${id}`, body, config);
      } catch (err) {
        console.log(err.message);
      }
    };
    function autoSave() {
      handleSave();
    }
    setTimeout(() => {
      autoSave();
    }, 3000);
  }, [name, id]);
  const handleShare = () => {
    navigator.clipboard.writeText(`https://researchai.co/posts/${id}`);
    setShare("link copied");
  };

  useEffect(() => {
    setTimeout(() => {
      setShare("share");
    }, 700);
  }, [share]);
  return (
    <div>
      <nav
        id="megaheader"
        className="p-1  fixed-top navbar-white bg-white border-bottom"
      >
        <div className="container-fluid">
          <span
            onClick={() => history.push("/dashboard")}
            className=" text-secondary nav-item navbar-brand rounded"
            style={{ cursor: "pointer" }}
          >
            <small>
              <i className="bi bi-arrow-left"></i> Home
            </small>
          </span>
          <input
            type="text"
            className="border-0 rounded "
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <div id="words" className="d-inline-flex">
            <span
              className="rounded text-secondary"
              style={{ marginRight: "25px" }}
            >
              <i className="bi bi-blockquote-left"></i>{" "}
              <small>
                {" "}
                <a
                  className="mt-5 h7 text-secondary"
                  target="_blank"
                  href="https://bloossom.typeform.com/to/IKCrahIz"
                >
                  Give feedback
                </a>
              </small>{" "}
            </span>

            <span
              className="text-secondary rounded"
              onClick={() => history.push("/dashboard")}
              style={{ marginRight: "25px", cursor: "pointer" }}
            >
              <small>Watch tutorials</small>
            </span>
            <span
              onClick={() => handleShare()}
              className="rounded text-secondary"
              style={{ marginRight: "25px", cursor: "pointer" }}
            >
              <i className="bi bi-share"></i> <small> {share}</small>{" "}
            </span>
            <span className="rounded" style={{ marginRight: "25px" }}>
              <i className="bi bi-check text-success p"></i>{" "}
              <small>Auto Save On</small>{" "}
            </span>
            <span className="text-secondary rounded">
              {" "}
              <small>Word count: {count}</small>
            </span>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default MegaHeader;

// <span className='text-secondary'
//  style={{marginRight: '25px', cursor: 'pointer'}}><small>SEO - Keyword Research Tool</small></span>

//     <nav className="navbar navbar-expand-lg  navbar-light">
//       <div className="container">
//         <ul className="navbar-nav navbar-nav-secondary order-lg-3">

//           <li className="nav-item">
//             <a className="nav-link nav-icon" data-bs-toggle="offcanvas" href="#offcanvasNav" role="button"
//               aria-controls="offcanvasNav">
//               <span className="bi bi-list"></span>
//             </a>
//           </li>
//         </ul>
//       </div>
//     </nav>

//     <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNav" aria-labelledby="offcanvasNavLabel">
//       <div className="offcanvas-header">
//         <h5 className="offcanvas-title" id="offcanvasNavLabel">Menu</h5>
//         <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
//       </div>
//       <div className="offcanvas-body">

//       </div>
//       <div className="offcanvas-footer">

//       </div>
//     </div>
