import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import PlanSize from "components/common/PlanSize";

const Plans = ({
  plan,
  isFreePlan,
  setPlan,
  user,
  checkedType,
  setCheckedType,
  isCanceled,
}) => {
  const word =
    user.subId !== "trial" && user.subId !== "active" && user.subId
      ? "Update"
      : "Connect";
  const annuallyPlans = ['$199.00','$79.00','$55.00','$19.00']
  const [selectedSize, setSelectedSize] = useState("50k");
  const getPrice = () => {
    switch (selectedSize) {
      case "50k":
        return checkedType ? "$55.00" : "$69.00";
      case "100k":
        return checkedType ? "$79.00" : "$99.00";
      default:
        return checkedType ? "$199.00" : "$249.00";
    }
  };
  const getToUpdatePrice = () => {
    switch (selectedSize) {
      case "50k":
        return checkedType ? "$660.00" : "$69.00";
      case "100k":
        return checkedType ? "$948.00" : "$99.00";
      default:
        return checkedType ? "$2,388.00" : "$249.00";
    }
  };

  useEffect(() => {
    if (plan.name && plan.name.includes("Pro")) {
      switch (plan.wordsLimit) {
        case 100000:
          setSelectedSize("100k");
          break;
        case 50000:
          setSelectedSize("50k");
          break;
        default:
          setSelectedSize("300k");
          break;
      }
    }
    if(annuallyPlans.includes(plan.price) && !checkedType){
      setCheckedType(true)
    }
  }, [plan]);

  const isActiveProPlan = (price) => {
    if (
      price === "$55.00" ||
      price === "$79.00" ||
      price === "$199.00" ||
      price === "$69.00" ||
      price === "$99.00" ||
      price === "$249.00"
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="plans">
      <div className="title__section">Select a plan</div>
      <div className="plans__checkbox">
        <div className="basic__plan__title plan__item__title">Bill monthly</div>
        <div className="switch">
          <label className="toggle">
            <input
              className={`toggle-checkbox `}
              type="checkbox"
              checked={checkedType}
              onChange={(e) => setCheckedType(e.target.checked)}
            />
            <div className={`toggle-switch `} />
          </label>
        </div>
        <div className="basic__plan__title plan__item__title">
          Bill annually
        </div>
      </div>
      <div className="plans__wrapper">
        <div className="basic__plan plan__item">
          {((plan && plan.price === "$0.00") || isFreePlan) && (
            <div className="basic__plan__selected">
              {isCanceled ? "Cancel" : "Active"}
            </div>
          )}
          <div className="basic__plan__top">
            <div className="basic__plan__title plan__item__title">
              Free Plan
            </div>
            <div className="basic__plan__subtitle plan__item__subtitle">
              2k word limit
            </div>
            {checkedType && (
              <div className="basic__plan__subtitle plan__item__subtitle">
                Bill annually
              </div>
            )}
          </div>
          <div className="basic__plan__middle plan__item__price">
            $0.00
            <span>/ month</span>
          </div>
          <div>
            <button className="btn-second" onClick={() => setPlan(`$0.00`)}>
              {word}
            </button>
          </div>
        </div>
        <div className="basic__plan plan__item">
          {plan && plan.price === (!checkedType ? "$29.99" : "$19.00") && (
            <div className="basic__plan__selected">
              {isCanceled ? "Cancel" : "Active"}
            </div>
          )}
          <div className="basic__plan__top">
            <div className="basic__plan__title plan__item__title">
              Standard Plan
            </div>
            <div className="basic__plan__subtitle plan__item__subtitle">
              20k word limit
            </div>
            {checkedType && (
              <div className="basic__plan__subtitle plan__item__subtitle">
                Bill annually
              </div>
            )}
          </div>
          <div className="basic__plan__middle plan__item__price">
            ${!checkedType ? "29.99" : "19.00"}
            <span>/ month</span>
          </div>
          <div>
            <button
              className="btn-second"
              onClick={() => setPlan(`$${!checkedType ? "29.99" : "228.00"}`)}
            >
              {word}
            </button>
          </div>
        </div>
        <div className="pro__plan plan__item">
          {plan && isActiveProPlan(plan.price) && (
            <div className="pro__plan__selected">
              {isCanceled ? "Cancel" : "Active"}
            </div>
          )}
          <div className="pro__plan__top">
            <div className="pro__plan__title  plan__item__title">Pro Plan</div>
            <div className="pro__plan__subtitle plan__item__subtitle">
              {selectedSize === '300k' ? 'Unlimited': selectedSize + ' word limit'}
            </div>
            {checkedType && (
              <div className="pro__plan__subtitle plan__item__subtitle">
                Bill annually
              </div>
            )}
          </div>
          <div className="pro__plan__middle plan__item__price">
            {getPrice()}
            <span>/ month</span>
          </div>
          <PlanSize
            selectedCount={selectedSize}
            onSelect={(value) => setSelectedSize(value)}
          />
          <div>
            <button
              className="btn-second"
              onClick={() => setPlan(getToUpdatePrice())}
            >
              {word}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

Plans.propTypes = {
  setPlan: PropTypes.func,
  setCheckedType: PropTypes.func,
  plan: PropTypes.object,
  checkedType: PropTypes.bool,
  isCanceled: PropTypes.bool,
  isFreePlan: PropTypes.bool,
};

export default Plans;
