import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
// import { setAlert } from "../../actions/alert";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
toast.configure();

const PasswordReset = () => {
  let history = useHistory();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({ email, password });
      const res = await axios.patch("/api/users/reset", body, config);
      toast.success("Password updated. Now Login.", {
        position: toast.POSITION.TOP_CENTER,
      });
      if (res.status === 200) {
        history.push("/login");
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  // return <Redirect to={{ pathname: '/dashboard' }} />

  return (
    <div>
      <section className="bg-black overflow-hidden">
        <div className="py-15 py-xl-20 d-flex flex-column container level-3 min-vh-100">
          <div className="row align-items-center justify-content-center my-auto">
            <div className="col-md-10 col-lg-8 col-xl-5">
              <div className="card">
                <div className="card-header bg-white text-center pb-0">
                  <h5 className="fs-4 mb-1">Welcome back.</h5>
                </div>
                <div className="card-body bg-white">
                  <p className="mb-2"> Reset your password here.</p>
                  <form onSubmit={(e) => onSubmit(e)}>
                    <div className="form-floating mb-2">
                      <input
                        type="email"
                        className="form-control"
                        id="floatingInput"
                        placeholder="jeff@amazon.com"
                        name="email"
                        value={email}
                        onChange={(e) => onChange(e)}
                        required
                      />
                      <label htmlFor="floatingInput">
                        Your Registered Email Address
                      </label>
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="password"
                        className="form-control"
                        id="floatingPassword"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={(e) => onChange(e)}
                        required
                      />
                      <label htmlFor="floatingPassword">New Password</label>
                    </div>
                    <div className="d-grid mb-2">
                      <button className="btn btn-lg btn-primary">
                        Reset Password
                      </button>
                    </div>
                  </form>
                </div>
                <div className="card-footer bg-opaque-black inverted text-center">
                  <p className="text-secondary">
                    Don't have an account yet?{" "}
                    <Link to="/register" className="underline">
                      Register
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <figure
          className="background background-overlay"
          style={{ backgroundImage: "url('/dist/assets/images/newyork.jpg')" }}
        ></figure>
      </section>
    </div>
  );
};

export default PasswordReset;
