import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";
import { toast } from "react-toastify";
import SideBar from "../SideBar/SideBar";
import { setTemplate } from "../../../../actions/templates";
import { TemplatesFilter } from "./templatesComponents/templatesFilter";
import { getOuputs } from "../../../../utils/queris/dashboard";
import TemplatesItems from "./templatesComponents/templatesItems";
import { getTemplatesByIcon } from "../../../../utils/getTemplatesByIcon";
import store from "store";
import { loadUser } from "actions/auth";
toast.configure();

const Templates = ({
  template,
  auth: { isAuthenticated, loading,user },
  setTemplateClick,
}) => {
  const history = useHistory();
  const handleSelect = (item) => {
    setTemplateClick(item);
    history.push("/tools/template");
  };
  const [selectedFilterType, setSelectedFilterType] = useState("All");
  const [wordsCount, setWordsCount] = useState(0);
  const [isLoadedWordsCount,setIsLoadedWordsCount] = useState(false)
  const [templates, setTemplates] = useState(
    getTemplatesByIcon(selectedFilterType)
  );
  useEffect(() => {
    if(isLoadedWordsCount){
      return 
    }
   if(user){
     setWordsCount(user.wordsCount)
   }
   store.dispatch(loadUser());
   setIsLoadedWordsCount(true)
  }, [user]);
  useEffect(() => {
    setTemplates(getTemplatesByIcon(selectedFilterType));
  }, [selectedFilterType]);
  // if (isAuthenticated === false) {
  //   return <Redirect to={{ pathname: "/login" }} />;
  // }

  return (
    <div className="ai">
      <SideBar user={user && user.name} />
      <div className="ai__container">
        <div className="wrapper">
          <div className="wrapper__title">AI Templates</div>
          <TemplatesFilter
            selectedItem={selectedFilterType}
            setItem={setSelectedFilterType}
          />
          <div className="ai__counts">
            Keep up the good work! You have generated
            <span>{" " + wordsCount.toLocaleString()} words </span>
            and saved <span>{Math.ceil(wordsCount / 260)} hours </span>
            of work so far
          </div>
          <TemplatesItems handleSelect={handleSelect} templates={templates} />
        </div>
      </div>
    </div>
  );
};

Templates.propTypes = {
  setTemplateClick: PropTypes.func,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    template: state.template,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTemplateClick: (template) => dispatch(setTemplate(template)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Templates);
