import React from "react";
import "./index.scss";
import image1 from "assets/images/step1.png";
import image2 from "assets/images/step2.png";
import image3 from "assets/images/step3.png";
import image4 from "assets/images/step4.png";

const LandingSliderRight = ({ selectedVariant }) => {
  const returnImage = () => {
    switch (selectedVariant) {
      case "first":
        return image1;
      case "second":
        return image2;
      case "third":
        return image3;
      default:
        return image4;
    }
  };

  return (
    <div className="landing__right">
      {selectedVariant === "first" && (
        <div className="block-1 block">🚀 Easy To Generate </div>
      )}
      {selectedVariant === "first" && (
        <div className="block-2 block">🔥 Up to 10 outputs</div>
      )}
      {selectedVariant === "second" && (
        <div className="block-3 block">💣 A lot of templates</div>
      )}
      {selectedVariant === "third" && (
        <div className="block-4 block">💡 Easy with Keywords!</div>
      )}
      {selectedVariant === "fourty" && <div className="block-5 block">👏</div>}
      <img src={returnImage()} alt="" />
    </div>
  );
};

export default LandingSliderRight;
