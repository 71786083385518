import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import Modal from "../../../common/Modal";
import Icon from "../../../common/Icon";
import Input from "../../../common/Inputs/index";
import axios from "axios";
import { toast } from "react-toastify";
import InvidetUser from "./InvidetUser";
import { uniqueId } from "lodash";
import Loader from "../../../common/Loader";
import { StyledTooltip } from "../../../private/sections/SideBar/SideBar";
import { IconButton } from "@mui/material";

const ProfileTeam = ({ user }) => {
  const [isOpenInviteModal, setIsOpenInviteModal] = useState(false);
  const [inputs, setInputs] = useState({
    email: "",
  });
  function myFunction(text) {
    navigator.clipboard.writeText(text);
    toast.success("Copied!");
  }
  const url = `https://new-reserach.herokuapp.com/register?isInvited=${user._id}`;
  const [isLoading, setIsLoading] = useState(false);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const InviteMember = async () => {
    setIsLoading(true);
    setIsOpenInviteModal(false);
    try {
      const data = await axios.post("/api/users/invite", {
        email: inputs.email,
      });
      toast.success('Member invited successfully')
      setInvitedUsers([...invitedUsers, data.data.user]);
    } catch (error) {
      error.response.data.errors.forEach((error) => {
        toast.error(error.msg);
      });
    }
    setIsLoading(false);
  };
  const removeMember = async (id) => {
    setIsLoading(true);
    try {
      await axios.delete(`/api/users/team-members/${id}`);
      setInvitedUsers([...invitedUsers.filter((user) => user._id !== id)]);
    } catch (error) {
      error.response.data.errors.forEach((error) => {
        toast.error(error.msg);
      });
    }
    setIsLoading(false);
  };
  const makeAnAdmin = async (id, role) => {
    setIsLoading(true);
    try {
      await axios.post(`/api/users/team-members/change-role/${id}`, {
        role,
      });
      setInvitedUsers([
        ...invitedUsers.map((user) => {
          return {
            ...user,
            role: user._id === id ? role : user.role,
          };
        }),
      ]);
    } catch (error) {
      console.log(error);
      error.response.data.errors.forEach((error) => {
        toast.error(error.msg);
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    const getUsers = async () => {
      try {
        const { data } = await axios.get("/api/users/team-members");
        setInvitedUsers([...data]);
      } catch (error) {
        console.log(error);
      }
    };
    getUsers();
  }, []);
  return (
    <div className="team__members">
      {isLoading && <Loader />}
      <div className="team__members__top">
        <div className="team__members__top__left">
          <div className="title">Team members</div>
          <div className="subtitle">
            Collaborate with others in your workspace
          </div>
        </div>
        <button onClick={() => setIsOpenInviteModal(true)} className="btn-main">
          Invite Team members
        </button>
      </div>
      <div className="break__line"></div>
      <div className="members">
        <div className="members__top">
          <div className="members__top__text">Member</div>
          <div className="members__top__text" style={{ minWidth: "149px" }}>
            Role
          </div>
        </div>
        <div className="invited__members">
          {invitedUsers.map((user) => {
            return (
              <InvidetUser
                makeAnAdmin={makeAnAdmin}
                removeMember={removeMember}
                user={user}
                key={uniqueId()}
              />
            );
          })}
        </div>
      </div>
      {isOpenInviteModal && (
        <Modal onClose={() => setIsOpenInviteModal(false)}>
          <div className="invite__modal__content">
            <div className="title">Invite your team</div>
            <div className="invite__modal__form">
              <Input
                name="email"
                value={inputs.email}
                label="Friend’s email"
                isHaveInviteButton={true}
                onInvite={() => InviteMember()}
                placeholder="Enter a member email"
                onChange={(name, value) =>
                  setInputs({ ...inputs, email: value })
                }
              />
            </div>
            <div className="invite__modal__bottom">
              <div className="text">
                Share this secret link with your allowed team members to allow
                them to join your account
              </div>

              <div className="url">
                <div
                  className="link"
                  onClick={() => window.open(url, "_blank")}
                >
                  {url}
                </div>
                <div className="url__copy">
                  <StyledTooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "common.black",
                          "& .MuiTooltip-arrow": {
                            color: "common.black",
                          },
                        },
                      },
                    }}
                    title="Copy"
                    placement="top"
                  >
                    <IconButton>
                      <Icon name="Copy" onClick={() => myFunction(url)} />
                    </IconButton>
                  </StyledTooltip>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

ProfileTeam.propTypes = {
  user: PropTypes.object,
};

export default ProfileTeam;
