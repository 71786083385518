import axios from "axios";
import {
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  AUTH_ERROR,
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  UPDATE_USER,
  INVITED__USER__LOADED,
  SIGNUP_START,
  USER_LOAD_END,
  USER_LOAD_START,
  UPDATE_MEMBER,
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch({
    type: USER_LOAD_START,
  });
  try {
    const res = await axios.get("/api/users");
    if (res.data.invitedUser) {
      dispatch({
        type: USER_LOADED,
        payload: {
          data: {
            ...res.data.user,
            name: res.data.invitedUser.name,
            email: res.data.invitedUser.email,
            subscription: res.data.user.subId,
            expired: res.data.expired,
            subId: res.data.expired ? null : res.data.user.subId,
            language: res.data.invitedUser.language,
            expiredWordsLimit: res.data.expiredWordsLimit
          },
          other:{
            canceled: res.data.canceled
          }
        },
      });
      dispatch({
        type: INVITED__USER__LOADED,
        payload: res.data.invitedUser
      });
    } else {
      dispatch({
        type: USER_LOADED,
        payload: {
          data:{
            ...res.data.user,
            subscription: res.data.user.subId,
            expired: res.data.expired,
            subId: res.data.expired ? null : res.data.user.subId,
            expiredWordsLimit: res.data.expiredWordsLimit
          },
          other: {
            canceled: res.data.canceled
          }
        },
      });
    }
    if (typeof window.Beacon === 'function') {
        window.Beacon('identify', {
           name: res.data.user.name,
           email: res.data.user.email,
        });
     }
    dispatch({
      type: USER_LOAD_END,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
    dispatch({
      type: USER_LOAD_END,
    });
  }
};

// sign up user

export const register =
  ({
    name,
    email,
    password,
    student,
    hear,
    referral,
    customerId,
    planId,
    isInvited,
    subId,
  }) =>
    async (dispatch) => {
      dispatch({
        type: SIGNUP_START,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        name,
        email,
        password,
        student,
        hear,
        referral,
        customerId,
        planId,
        subId,
        isInvited,
      });
      try {
        const res = await axios.post("/api/users", body, config);
        dispatch({
          type: SIGNUP_SUCCESS,
          payload: res.data,
        });
        dispatch(loadUser());

        toast.success("Welcome to Research AI!", {
          position: toast.POSITION.TOP_CENTER,
        });
      } catch (err) {
        if (err.response && err.response.data && err.response.data.errors) {
          for (let i = 0; i < err.response.data.errors.length; i++) {
            toast.error(err.response.data.errors[i].msg)
          }
        }
        dispatch({
          type: SIGNUP_FAIL,
        });
      }
    };

// login user

export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email, password });
  try {
    const res = await axios.post("/api/users/auth", body, config);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    window.location.reload()
    dispatch(loadUser());
  } catch (err) {
    toast.error("Invalid email or password. Try again.", {
      position: toast.POSITION.TOP_RIGHT,
    });
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};


// forget password

export const forgetPassword = (email) => async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email });
  try {
    await axios.post("/api/users/forget-password", body, config);
    toast.success('The new password has been sent to your email.', {
      position: toast.POSITION.TOP_RIGHT,
    }); 
  } catch (err) {
    if(err.response?.data?.errors[0]?.msg) {
      toast.error(err.response.data.errors[0].msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error("Something went wrong.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
};

export const logout = () => (dispatch) => {
    dispatch({ type: LOGOUT });
    if (typeof window.Beacon === 'function') {
        window.Beacon('logout')
    }
};

export const changeUser = (user, token) => (dispatch) => {
  dispatch({
    type: UPDATE_USER,
    payload: {
      user,
      token,
    },
  });
};

export const changeMember = (user, token) => (dispatch) => {
  dispatch({
    type: UPDATE_MEMBER,
    payload: {
      user,
      token,
    },
  });
}