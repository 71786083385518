import React, { useState } from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 6,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #f0f1ff",
    fontSize: 14,
    padding: "12px 14px",
    "&:hover": {
      boxShadow: " 0px 6px 16px rgba(183, 183, 183, 0.25)",
    },
    transition: "0.3s easy",
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      borderColor: "#5a64e7",
    },
  },
}));

const CustomSelect = ({
  value,
  label,
  placeholder,
  name,
  onChange,
  options,
  disabled
}) => {
  const [isOpenLanguageSelect, setIsOpenLanguageSelect] = useState(false);
  return (
    <div className="input">
      <div className="input__top">
        {label && <div className="input__top__label">{label}</div>}
      </div>
      <Select
        open={isOpenLanguageSelect}
        name="language"
        className="custom-select"
        onClose={() => setIsOpenLanguageSelect(false)}
        onOpen={() => setIsOpenLanguageSelect(true)}
        value={value}
        disabled={disabled}
        input={<BootstrapInput />}
        onChange={(event) => onChange(event.target.name, event.target.value)}
      >
        {options.map((option) => {
          return (
            <MenuItem key={uuidv4()} value={option.value}>
              {option.key}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

CustomSelect.propTypes = {
  name: PropTypes.any,
  label: PropTypes.any,
  placeholder: PropTypes.any,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  maxHeight: PropTypes.number,
  tooltipText: PropTypes.any,
};

export default CustomSelect;
