import React, { useState } from "react";
import PropTypes from "prop-types";
import Input from "../../../../../common/Inputs";
import "./index.scss";
import TextArea from "../../../../../common/TextArea";
import CustomSelect from "../../../../../common/Selects";
import SelectValue from "../../../../../common/SelectValue";
import { languages } from "../../../../../../utils/languageOptions";
import Icon from "../../../../../common/Icon";
import Modal from "components/common/Modal";
import { useHistory } from "react-router";

const EditorLeft = ({
  inputs,
  handleInputChange,
  cleanButton,
  handleSubmit,
  isLoading,
  newDocument = false,
  template,
  user,
  isOpenExpireModal,
  setIsOpenExpireModal
}) => {
  const onSumbit = () => {

    // if (user.expiredWordsLimit) {
    //   setIsOpenExpireModal(true);
    //   return;
    // }
    handleSubmit();
  };
  const history = useHistory()
  const [isDisabledSelector,setIsDisabledSelector] = useState(false)

  return (
    <div className="editor__left">
      {isOpenExpireModal && (
        <Modal
          hasCloseButton={false}
          onClose={() => setIsOpenExpireModal(false)}
        >
          <div className="editor__left__expire">
            <div className="title">Word Limit Exceeded</div>
            <div className="subtitle">
              You have exceeded your word limit. Please upgrade your account to continue writing.
            </div>
          <div className="buttons">
            <button 
              onClick={() => setIsOpenExpireModal(false)}
              className="btn-second"
            >
                Not Now
            </button>
          <button
              className="btn-main"
              onClick={() => {
                history.push('/settings#billing')
              }}
            >
              Upgrade
            </button>
          </div>
          </div>
        </Modal>
      )}
      {/*  to do */}
      {/* {newDocument && (
         <div className="editor__new__document">
            <div className="icon">
              <Icon name={template.icon} />
            </div>
         <div className="editor__new__document__text">
           <div className="title">{template.title}</div>
           <div className="description">{template.description}</div>
         </div>
       </div>
      )} */}
      <Input
        name="name"
        value={inputs.name}
        placeholder="Enter a Product Name"
        label="Product Name"
        optional="Optional"
        onChange={handleInputChange}
      ></Input>
      <TextArea
        name="content"
        value={inputs.content}
        placeholder="Enter a content..."
        label="Content"
        maxHeight={128}
        tooltipText="We'll generate ideas based on this"
        onChange={handleInputChange}
      />
      <div className={newDocument ? "flex-row-new" : "flex"}>
        <Input
          name="voice"
          value={inputs.voice}
          placeholder="Enter a Tone of voice"
          label="Tone of voice"
          optional="Optional"
          onChange={handleInputChange}
        ></Input>
        {/* <CustomSelect
          name="language"
          options={languages}
          value={inputs.language}
          label="Language of Output"
          onChange={handleInputChange}
          disabled={isDisabledSelector}
        /> */}
      </div>
      {newDocument ? (
        <SelectValue
          name="size"
          options={["S", "M", "L"]}
          value={inputs.size}
          label="Select text length"
          onChange={handleInputChange}
        />
      ) : (
        <SelectValue
          name="amount"
          options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          value={inputs.amount}
          label="Select Outputs amount"
          onChange={handleInputChange}
          tooltipText="Amount of copies that system will generate"
        />
      )}
      <button
        className="btn-main"
        onClick={isLoading ? () => {} : () => {
          onSumbit();
          setIsDisabledSelector(true)
        }}
      >
        {isLoading ? <Icon name="Loader" /> : "Generate AI content"}
      </button>
      <div
        role="presentation"
        onClick={() => {
          cleanButton()
          setIsDisabledSelector(false)
        }}
        className="btn-clean"
      >
        Clean Up
      </div>
    </div>
  );
};

EditorLeft.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  inputs: PropTypes.object,
  handleSubmit: PropTypes.func,
  cleanButton: PropTypes.func,
  newDocument: PropTypes.bool,
  template: PropTypes.object,
  isLoading: PropTypes.bool,
  isOpenExpireModal: PropTypes.bool,
  setIsOpenExpireModal: PropTypes.func,
};

export default EditorLeft;
