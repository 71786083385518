import React, { useState } from "react";
import { Link } from "react-router-dom";
import TextArea from "../../common/TextArea";
import "./index.scss";
import image from "assets/images/LandingImage.png";
import image2 from "assets/images/LandingImage.png";
import Icon from "../../common/Icon";
import { useWindowSizeChange } from "utils/useWindowSizeChange";

const Stroy = () => {
  const [value, setValue] = useState("");
  const windowSizes = useWindowSizeChange();
  return (
    <div className="story">
      <div className="story__wrapper">
        <div className="story__top">
          <div className="story__top__title">
            Write Your Own Success Story <br /> And Let <span>ResearchAI</span>{" "}
            Help You With That!
          </div>
          <div className="story__top__subtitle">
            Get ResearchAI and start creating the content you need to produce
            courses faster and immediately grow your profits.
          </div>
        </div>
        <div className="story__bottom">
          {windowSizes.innerWidth > 1024 && (
            <div className="story__bottom__left">
              <img src={image} alt="" />
              <div className="story__bottom__twitter">
                <Icon name="Twitter" />
              </div>
              <div className="story__bottom__facebook">
                <Icon name="Facebook" />
              </div>
              <div className="story__bottom__input">
                <TextArea
                  value={value}
                  placeholder="Enter a content..."
                  onChange={(name, i) => setValue(i)}
                />
              </div>
            </div>
          )}
          <div className="story__bottom__right">
            <div className="story__bottom__text">
              Remember, you can use this AI tool to generate course content and
              improve the copy you write in various formats like Twitter,
              Facebook, LinkedIn, Google Docs, Gmail, and more.
            </div>
            <div className="story__bottom__title">
              Once you start using <span>ResearchAI </span>
              <br />
              you will wonder how you got <br /> along without it …
              <div className="story__bottom__title__block">
                👏 Excellent content
              </div>
            </div>
            <div className="story__bottom__text">
              And you are sure to be extremely thankful you finally found this
              tool!
            </div>
            <Link to="/register" className="btn-main">
              Sign Up Free
            </Link>
            {windowSizes.innerWidth < 1024 && (
              <div className="story__bottom__left">
                <div className="story__bottom__twitter">
                  <Icon name="Twitter" />
                </div>
                <div className="story__bottom__facebook">
                  <Icon name="Facebook" />
                </div>
                <img src={image2} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stroy;
