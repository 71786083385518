import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const CommentBlock = ({ version, title, description, account, iconName }) => {
  if (version === "white") {
    return (
      <div className="comment__block comment__block__right">
        <div className="comment__block__title">{title}</div>
        <div className="comment__block__description">{description}</div>
        <div className="comment__block__account">
          <div className="comment__block__account__left">
            <div className="image">
              <img src={account.src} alt="" />
            </div>
            <div className="left__text">
              <div className="left__text__title">{account.title}</div>
              <div className="left__text__description">
                {account.description}
              </div>
            </div>
          </div>
          <div>{account.text}</div>
        </div>
      </div>
    );
  }
  return (
    <div className="comment__block">
      <div className="comment__block__title">{title}</div>
      <div className="comment__block__description">{description}</div>
      <div className="comment__block__account">
        <div className="comment__block__account__left">
          <div className="image">
            <img src={account.src} alt="" />
          </div>
          <div className="left__text">
            <div className="left__text__title">{account.title}</div>
            <div className="left__text__description">{account.description}</div>
          </div>
        </div>
        <div>{account.text}</div>
      </div>
    </div>
  );
};

CommentBlock.propTypes = {
  version: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  account: PropTypes.object,
  iconName: PropTypes.string,
};

export default CommentBlock;
