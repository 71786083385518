/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import axios from "axios";
import NavBar from "./NavBar";
import Editor from "rich-markdown-editor";
import Loading from "../../common/Loading";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
const keyword_extractor = require("keyword-extractor");

toast.configure();

const ContentBrief = () => {
  const [inputTitle, setInputTitle] = useState("");
  const [content, setContent] = useState("");

  const [briefData, setBriefData] = useState(
    localStorage.getItem("brief")
      ? JSON.parse(localStorage.getItem("brief"))
      : {
          title: "",
          description: "",
          audience: "",
          keywords: [],
          seoTitle: "",
          seoDesc: "",
          wordCount: "",
          internalLinks: "",
          competitorArticles: [],
          relatedSearch: [],
          notes: "",
          cta: "",
        }
  );

  const [serpData, setSerpData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [relatedQs, setRelatedQs] = useState([]);
  const [webPages, setwebPages] = useState([]);

  const handleSubmit = async () => {
    if (!inputTitle.length) {
      alert("Article title field can't be empty.");
    } else {
      setLoading(true);
      try {
        const promise = await axios.post("/api/documents/serp", {
          query: inputTitle,
        });
        const promise1 = await axios.post("/api/index/content-brief", {
          text: "Write seo meta title for a blog article about " + inputTitle,
          token: 25,
        });
        const promise2 = await axios.post("/api/index/content-brief", {
          text:
            "Write seo meta description for a blog article about " + inputTitle,
          token: 55,
        });

        const dataSerp = promise.data;

        const metaTitleText = promise1.data["choices"][0]["text"];
        const metaDescText = promise2.data["choices"][0]["text"];
        setSerpData(dataSerp);
        setwebPages(promise.data.webPages.value);
        setRelatedQs(promise.data.relatedSearches.value);

        // const relatedQs = await serpData.relatedSearches.value
        // const webPages = await serpData.webPages.value
        // setWeb(webPages)

        setBriefData({
          ...briefData,
          seoTitle: metaTitleText,
          seoDesc: metaDescText,
          title: inputTitle,
          keywords: [],
          audience: "Who will read this article? (Target audience)",
          description: "Describe purpose of this article in 1 sentence.",
          wordCount: "1200 - 1500 words",
          internalLinks: "Enter links that you want to include in the article",
          competitorArticles: promise.data.webPages.value,
          relatedSearch: promise.data.relatedSearches.value,
          cta: "What's your call to action?",
          notes: "Your notes",
        });
        setLoading(false);
      } catch (err) {
        toast.error("You've reached daily limit.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        // console.error(err)
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    localStorage.setItem("brief", JSON.stringify(briefData));
  }, [briefData]);

  useEffect(() => {
    const exctractKeywords = () => {
      // const webPages = serpData.webPages.value

      let arrayOfString = [];
      for (let item of briefData.competitorArticles
        ? briefData.competitorArticles.slice(0, 5)
        : []) {
        arrayOfString.push(item.snippet);
      }
      const newString = arrayOfString.toString();
      const extraction_result = keyword_extractor.extract(newString, {
        language: "english",
        remove_digits: false,
        return_changed_case: true,
        remove_duplicates: true,
        return_chained_words: true,
      });
      setBriefData({ ...briefData, keywords: extraction_result });
      // setKwArray(extraction_result)
    };
    exctractKeywords();
  }, [briefData.competitorArticles]);

  const renderResult = () => {
    if (!loading) {
      return (
        <Editor
          className="mt-10"
          id="markdown"
          defaultValue={content}
          onChange={(getValue) => {
            setContent(getValue());
          }}
          value={`**1. Topic/Title:** ${
            briefData.title
          } \n **2. Target Audience:** ${briefData.audience} 
                \n **3. SEO Meta Title:** ${
                  briefData.seoTitle
                } \n **4. SEO Meta Description:** ${briefData.seoDesc} 
                \n **5. Estimated Word Count:** ${
                  briefData.wordCount
                } \n **6. Internal Links:** ${briefData.internalLinks}
                \n **7. Call-To-Action:** ${briefData.cta}
                \n **8. Notes:** ${briefData.notes}
                \n #### Keywords: \n ${briefData.keywords.map(
                  (item, id) => `${item} `
                )}
                \n #### Related Searches: \n ${briefData.relatedSearch.map(
                  (item, id) => `**${id + 1}.** ${item.text}`
                )}   
                \n #### Competitor Articles: \n ${briefData.competitorArticles
                  .slice(0, 5)
                  .map(
                    (item) =>
                      `[**${item.name}**](${item.url})\n ${item.snippet} \n`
                  )}`}
          placeholder="Type / to see the list of commands."
        />
      );
      //  return <TextareaAutosize className="form-control border-0"  minRows="3"
      //         value={`- Search term: ${briefData.title}`} onChange={e => setItem(e.target.value)}

      //   ></TextareaAutosize>
    }
  };

  return (
    <div>
      <NavBar />
      <div className=" container">
        <div className="d-flex flex-column p-10">
          <h2 className="space-top-1">Content Brief</h2>
          <p>
            Created a detailed and well researched content brief for your
            articles with a single click.
          </p>
          <h6>Note: You can create a maximum of 5 content briefs per day.</h6>

          <div className="grouped-inputs p-1 rounded-pill border mt-10 ">
            <div className="row g-0 ">
              <div className="col ">
                <input
                  className="form-control"
                  placeholder="enter your blog article title or topic name"
                  type="text"
                  value={inputTitle}
                  onChange={(e) => setInputTitle(e.target.value)}
                />
              </div>
              <div className="col-auto d-grid">
                <button
                  onClick={handleSubmit}
                  className="btn btn-primary btn-m rounded-pill"
                >
                  <i className="bi bi-pencil-square"></i> Create
                </button>
              </div>
            </div>
          </div>

          {loading ? <Loading /> : renderResult()}
        </div>
      </div>
    </div>
  );
};

export default ContentBrief;
