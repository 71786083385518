import React from "react";
import "./index.scss";
import Icon from "../Icon";

const Loader = () => {
  return (
    <div className="loader">
      <Icon name="Loader" />
    </div>
  );
};

Loader.propTypes = {};

export default Loader;
