import PropTypes from "prop-types";

export const ArrowLeftIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4697 4.46967C13.7626 4.17678 14.2374 4.17678 14.5303 4.46967L21.5303 11.4696C21.671 11.6102 21.75 11.801 21.75 11.9999C21.75 12.1988 21.671 12.3896 21.5303 12.5303L14.5303 19.5303C14.2374 19.8232 13.7626 19.8232 13.4697 19.5303C13.1768 19.2374 13.1768 18.7625 13.4697 18.4696L19.1893 12.7499H3C2.58579 12.7499 2.25 12.4141 2.25 11.9999C2.25 11.5857 2.58579 11.2499 3 11.2499H19.1893L13.4697 5.53033C13.1768 5.23744 13.1768 4.76257 13.4697 4.46967Z"
        fill="#2F2F2F"
      />
    </svg>
  );
};

export const ArrowBackIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.89775 3.35225C8.11742 3.57193 8.11742 3.92808 7.89775 4.14775L3.608 8.43745H15.75C16.0607 8.43745 16.3125 8.68929 16.3125 8.99995C16.3125 9.31061 16.0607 9.56245 15.75 9.56245H3.608L7.89775 13.8522C8.11742 14.0719 8.11742 14.428 7.89775 14.6477C7.67808 14.8674 7.32192 14.8674 7.10225 14.6477L1.85225 9.3977C1.74676 9.29221 1.6875 9.14913 1.6875 8.99995C1.6875 8.85076 1.74676 8.70769 1.85225 8.6022L7.10225 3.35225C7.32193 3.13258 7.67808 3.13258 7.89775 3.35225Z"
        fill="#9F9F9F"
      />
    </svg>
  );
};

export const DoubleArrowLeftIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1475 3.39775C10.3672 3.17808 10.3672 2.82192 10.1475 2.60225C9.92785 2.38258 9.5717 2.38258 9.35203 2.60225L5.04908 6.9052L5.03294 6.92134L5.03294 6.92134C4.74966 7.20461 4.51321 7.44105 4.33582 7.65C4.15082 7.86792 3.99727 8.09266 3.90954 8.36265C3.77495 8.77689 3.77495 9.22311 3.90954 9.63735C3.99727 9.90734 4.15082 10.1321 4.33582 10.35C4.5132 10.5589 4.74965 10.7954 5.03292 11.0786L5.03294 11.0787L5.04908 11.0948L9.35203 15.3977C9.5717 15.6174 9.92785 15.6174 10.1475 15.3977C10.3672 15.1781 10.3672 14.8219 10.1475 14.6023L5.84458 10.2993C5.541 9.99573 5.33755 9.79166 5.19346 9.62193C5.05363 9.45722 5.00303 9.3622 4.97948 9.2897C4.9183 9.10141 4.9183 8.89859 4.97948 8.7103C5.00303 8.6378 5.05363 8.54278 5.19346 8.37807C5.33755 8.20834 5.541 8.00427 5.84458 7.70069L10.1475 3.39775ZM12.3975 6.39775C12.6172 6.17808 12.6172 5.82192 12.3975 5.60225C12.1779 5.38258 11.8217 5.38258 11.602 5.60225L9.35203 7.85225L8.60203 8.60225C8.38236 8.82192 8.38236 9.17808 8.60203 9.39775L9.35203 10.1477L11.602 12.3977C11.8217 12.6174 12.1779 12.6174 12.3975 12.3977C12.6172 12.1781 12.6172 11.8219 12.3975 11.6023L10.1475 9.35225L9.79527 9L10.1475 8.64775L12.3975 6.39775Z"
        fill="white"
      />
    </svg>
  );
};

export const CloseIcon = ({ className, onClick }) => {
  return (
    <svg
      width="18"
      onClick={onClick}
      height="18"
      viewBox="0 0 18 18"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.10225 4.10225C4.32192 3.88258 4.67808 3.88258 4.89775 4.10225L9 8.20451L13.1023 4.10225C13.3219 3.88258 13.6781 3.88258 13.8977 4.10225C14.1174 4.32192 14.1174 4.67808 13.8977 4.89775L9.79549 9L13.8977 13.1023C14.1174 13.3219 14.1174 13.6781 13.8977 13.8977C13.6781 14.1174 13.3219 14.1174 13.1023 13.8977L9 9.79549L4.89775 13.8977C4.67808 14.1174 4.32192 14.1174 4.10225 13.8977C3.88258 13.6781 3.88258 13.3219 4.10225 13.1023L8.20451 9L4.10225 4.89775C3.88258 4.67808 3.88258 4.32192 4.10225 4.10225Z"
        fill="#9F9F9F"
      />
    </svg>
  );
};

export const TriangleIcon = ({ color }) => {
  return (
    <svg
      width="5"
      height="16"
      viewBox="0 0 5 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.625 4.25C3.65625 4.25 4.5 3.40625 4.5 2.375C4.5 1.34375 3.65625 0.5 2.625 0.5C1.59375 0.5 0.75 1.34375 0.75 2.375C0.75 3.40625 1.59375 4.25 2.625 4.25ZM2.625 6.125C1.59375 6.125 0.75 6.96875 0.75 8C0.75 9.03125 1.59375 9.875 2.625 9.875C3.65625 9.875 4.5 9.03125 4.5 8C4.5 6.96875 3.65625 6.125 2.625 6.125ZM0.75 13.625C0.75 12.5938 1.59375 11.75 2.625 11.75C3.65625 11.75 4.5 12.5938 4.5 13.625C4.5 14.6562 3.65625 15.5 2.625 15.5C1.59375 15.5 0.75 14.6562 0.75 13.625Z"
        fill={color || "#9F9F9F"}
      />
    </svg>
  );
};
export const CloseFAQIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5 4.25C12.9142 4.25 13.25 4.58579 13.25 5V11.25H19.5C19.9142 11.25 20.25 11.5858 20.25 12C20.25 12.4142 19.9142 12.75 19.5 12.75H13.25V19C13.25 19.4142 12.9142 19.75 12.5 19.75C12.0858 19.75 11.75 19.4142 11.75 19V12.75H5.5C5.08579 12.75 4.75 12.4142 4.75 12C4.75 11.5858 5.08579 11.25 5.5 11.25H11.75V5C11.75 4.58579 12.0858 4.25 12.5 4.25Z"
        fill="#2F2F2F"
      />
    </svg>
  );
};

export const ArrowEnterIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5 1.875C17.8452 1.875 18.125 2.15482 18.125 2.5V8V8.02752C18.125 8.93763 18.125 9.65887 18.0775 10.2402C18.0289 10.8347 17.9276 11.3377 17.6936 11.797C17.3141 12.5419 16.7085 13.1474 15.9637 13.5269C15.5044 13.7609 15.0014 13.8623 14.4069 13.9108C13.8255 13.9583 13.1043 13.9583 12.1942 13.9583H12.1667H4.00888L7.10861 17.0581C7.35269 17.3021 7.35269 17.6979 7.10861 17.9419C6.86453 18.186 6.4688 18.186 6.22472 17.9419L2.05806 13.7753C1.81398 13.5312 1.81398 13.1355 2.05806 12.8914L6.22472 8.72472C6.4688 8.48065 6.86453 8.48065 7.10861 8.72472C7.35269 8.9688 7.35269 9.36453 7.10861 9.60861L4.00888 12.7083H12.1667C13.1104 12.7083 13.7806 12.7078 14.3051 12.665C14.8223 12.6227 15.1425 12.5424 15.3962 12.4131C15.9058 12.1535 16.3202 11.7392 16.5798 11.2296C16.7091 10.9758 16.7894 10.6556 16.8317 10.1384C16.8745 9.61395 16.875 8.94373 16.875 8V2.5C16.875 2.15482 17.1548 1.875 17.5 1.875Z"
        fill="white"
      />
    </svg>
  );
};

export const FunctionIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1005_5973)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.2534 2.72357C10.4061 2.86352 10.4164 3.10073 10.2764 3.2534L4.77643 9.2534C4.70732 9.3288 4.6104 9.37269 4.50815 9.37491C4.40589 9.37714 4.30716 9.33749 4.23483 9.26517L1.73483 6.76517C1.58839 6.61872 1.58839 6.38128 1.73483 6.23484C1.88128 6.08839 2.11872 6.08839 2.26517 6.23484L4.48822 8.45789L9.72357 2.74661C9.86351 2.59394 10.1007 2.58362 10.2534 2.72357Z"
          fill="#2F2F2F"
        />
      </g>
      <defs>
        <clipPath id="clip0_1005_5973">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

ArrowLeftIcon.propTypes = {};
CloseFAQIcon.propTypes = {};
ArrowBackIcon.propTypes = {};
DoubleArrowLeftIcon.propTypes = {};
CloseIcon.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};
TriangleIcon.propTypes = {
  color: PropTypes.string,
};
ArrowEnterIcon.propPtypes = {};
FunctionIcon.propTypes = {};
