import React from "react";
import "./index.scss";
import PropTypes from "prop-types";
import Icon from "components/common/Icon";

const HelpItem = ({ iconName, title, description }) => {
  return (
    <div className="help__content__item">
      <div className="help__content__item__icon">
        <Icon name={iconName} />
      </div>
      <div className="help__content__item__title">{title}</div>
      <div className="help__content__item__description">{description}</div>
    </div>
  );
};

HelpItem.propTypes = {
  iconName: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default HelpItem;
