import React from "react";
import LandingSlider from "components/common/LandingSlider";
import "./index.scss";

const Steps = () => {
  return (
    <div className="steps">
      <div className="steps__top">
        With <br /><span>ResearchAI</span> <br />
        You Get ALL of These Powerful Features
      </div>
      <div className="steps__content">
        <LandingSlider />
      </div>
    </div>
  );
};

Steps.propTypes = {};

export default Steps;
