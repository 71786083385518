import React from "react";
import { Link } from "react-router-dom";
import CardTemplate from "../../../../common/Card/index";
import "./index.scss";
import PropTypes from "prop-types";
import { dashboardTools } from "../../../sections/newWritingTools";

const DashboardTemplates = ({ wordsCount, handleSelect }) => {
  return (
    <div className="templates">
      <div className="templates__header">
        <div className="templates__header__top">
          <div className="title">Templates To Get Started</div>
          <Link className="templates__header__link" to="/tools">
            See all
          </Link>
        </div>
        <div className="templates__header__bottom">
          <div className="subtext">
            Keep up the good work! You have generated
            <span>{" " + wordsCount.toLocaleString()} words </span>
            and saved <span>{Math.ceil(wordsCount / 260)} hours </span>
            of work so far
          </div>
        </div>
      </div>
      <div className="templates__row">
        {dashboardTools.map((element) => {
          return (
            <CardTemplate
              onClick={() => handleSelect(element)}
              key={element.id}
              item={element}
            />
          );
        })}
      </div>
    </div>
  );
};

DashboardTemplates.propTypes = {
  handleSelect: PropTypes.func,
  wordsCount: PropTypes.number,
};

export default DashboardTemplates;
