import React from "react";
import logoRowImage from "assets/images/logos.png";
import logoRowImage2 from "assets/images/logos2.png";
import "./index.scss";
import { useWindowSizeChange } from "utils/useWindowSizeChange";

const Services = () => {
  const windowSize = useWindowSizeChange()
  console.log(windowSize)
  return (
    <div className="services">
      <div className="services__title">
        Thousands of Creators & Their Marketing Teams Trust ResearchAI
      </div>
      <img src={windowSize.innerWidth < 1024 ? logoRowImage2 : logoRowImage} alt="" />
    </div>
  );
};

Services.propTypes = {};

export default Services;
