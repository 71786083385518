import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import { setTemplate } from "../../../../actions/templates";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Icon from "../../../common/Icon";
import EditorLeft from "./editorComponents/editorLeft";
import EditorRight from "./editorComponents/editorRight";
import { toast } from "react-toastify";
import axios from "axios";
import Input from "../../../common/Inputs";
import { tools } from "../newWritingTools";
import { v4 as uuidv4 } from "uuid";
import Loader from "../../../common/Loader";

const TemplateEditor = ({
  clearTemplate,
  template,
  changeTemplate,
  auth: { user },
}) => {
  const [listItems, setlistItems] = useState([]);
  const [mainTools, setMainTools] = useState(tools);
  const history = useHistory();
  const [isLoadingTemplate,setIsLoadingTemplate] = useState(true)
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const BarRef = useRef(null);
  const [isOpenExpireModal,setIsOpenExpireModal] = useState(false)
  const [inputs, setInputs] = useState({
    name: "",
    content: "",
    voice: "",
    language: user && user.language ? user.language : "English",
    amount: 1,
  });
  useEffect(() => {
    setInputs({
      name: "",
      content: "",
      voice: "",
      language: user && user.language ? user.language : "English",
      amount: 1,
    });
  }, [user]);
  useEffect(() => {
    if (search.length) {
      setMainTools(tools.filter((e) =>  (e.title.toLowerCase()).includes(search.toLowerCase())));
    } else {
      setMainTools(tools);
    }
  }, [search]);
  const GoBack = () => {
    clearTemplate();
    history.goBack();
  };
  useEffect(()=>{
    if(!template.icon){
      GoBack()
    }else{
      setIsLoadingTemplate(false);
    }
  },[]);

  const handleInputChange = (name, value) => {
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const cleanButton = () => {
    setInputs({
      name: "",
      content: "",
      voice: "",
      language: user.language ? user.language : "English",
      amount: 1,
    });
    setlistItems([])
  };
  const RefreshAndChangeTemplate = (newTemplate) => {
    changeTemplate(newTemplate);
    setlistItems([])
    cleanButton();
    BarRef.current.style.right = "-100%";
  };
  const handleSubmit = async () => {
    if (!inputs.content || !inputs.content.trim()) {
      toast.error('Please fill required fields')
      return
    }
    let titleP = inputs.name.length ? inputs.name + "," : "";
    let toneP = inputs.voice.length
      ? `. Tone of voice is ${inputs.voice}.`
      : "";
    let lang = inputs.language.length
      ? `The output language is: ${inputs.language}.`
      : "";
    let apiPrompt = `${template.prompt} ${titleP}${inputs.content.trim()}${toneP} ${lang}`;
    setIsLoading(true);
    try {
      const promise = await axios.post("/api/index/beastmode", {
        text: apiPrompt,
        engine: template.engine,
        token: template.token,
        runs: inputs.amount,
      });

      const list = promise.data["choices"];
      setlistItems(list);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      if(err && err.response && err.response.data && err.response.data.raw && err.response.data.raw.message){
        if(err.response.data.raw.message === 'You can not generate document'){
          setIsOpenExpireModal(true)
          return
        }
        toast.error(err.response.data.raw.message)
        return
      }
      toast.error("Something went wrong. Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  return (
    <div className="ai__editor">
      {isLoadingTemplate ? (<Loader />) : (
      <div className="wrapper">
        <div className="ai__back" role="presentation" onClick={GoBack}>
          <Icon name="ArrowBack" />
          <span>Back</span>
        </div>
        <div className="editor__header">
          <div className="editor__header__left">
            <div className="icon">
              <Icon name={template.icon} />
            </div>
            <div className="editor__header__left__text">
              <div className="title">{template.title}</div>
              <div className="description">{template.description}</div>
            </div>
          </div>
          <div className="editor__header__right">
            <div
              className="bar__button"
              role="presentation"
              onClick={() => (BarRef.current.style.right = "0%")}
            >
              <Icon name="DoubleArrowLeft" />
              <span>Templates</span>
            </div>
            <div className="editor__hide__bar" ref={BarRef}>
              <div className="editor__hide__bar__wrapper">
                <Input
                  iconName="Search"
                  value={search}
                  onChange={(name, value) => setSearch(value)}
                />
                <div
                  className="editor__hide__bar__button"
                  role="presentation"
                  onClick={() => (BarRef.current.style.right = "-100%")}
                >
                  <Icon name="DoubleArrowLeft" />
                </div>
                <div className="editor__hide__bar__items">
                  {mainTools &&
                    mainTools.map((tool) => {
                      return (
                        <div
                          key={uuidv4()}
                          className="editor__hide__bar__item"
                          role="presentation"
                          onClick={() => RefreshAndChangeTemplate(tool)}
                        >
                          <div className="icon">
                            <Icon name={tool.icon} />
                          </div>
                          <div className="title">{tool.title}</div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="editor__content">
          <EditorLeft
            cleanButton={cleanButton}
            handleSubmit={handleSubmit}
            inputs={inputs}
            user={user}
            isOpenExpireModal={isOpenExpireModal}
            setIsOpenExpireModal={setIsOpenExpireModal}
            isLoading={isLoading}
            handleInputChange={handleInputChange}
          />
          <EditorRight
            content={listItems}
            handleReset={handleSubmit}
            title={template.title}
            isLoading={isLoading}
          />
        </div>
      </div>
      )}
    </div>
  );
};

TemplateEditor.propTypes = {
  clearTemplate: PropTypes.func,
  template: PropTypes.object,
  changeTemplate: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    template: state.template,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearTemplate: () => dispatch(setTemplate({})),
  changeTemplate: (template) => dispatch(setTemplate(template)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateEditor);
