/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import axios from "axios";
import { plans } from "../../sections/plans";
import { connect } from "react-redux";
import Plans from "../profileComponents/Plans";
import Modal from "../../../common/Modal";
import Payments from "../profileComponents/Payments";
import Loader from "../../../common/Loader";
import Icon from "../../../common/Icon";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { logout,loadUser as load } from "../../../../actions/auth";
import { AddPlanContent, CancelModal } from "../profileComponents/Modals";

const ProfileBiling = ({ user, logout,isCanceled,loadUser }) => {
  const [loading, setLoading] = useState(true);
  const [cardList, setCardList] = useState([]);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [planModalContent, setPlanModalContent] = useState({});
  const history = useHistory();
  const [checkedType, setCheckedType] = useState(false);
  const [plan, setPLan] = useState({
    isOpen: false,
  });
  const [isFreePlan,setIsFreePlan] = useState(false)
  const onSumbit = async (plan, cardId) => {
    setLoading(true);
    window.location = `${window.location}#billing`
    setPlanModalContent({
      ...planModalContent,
      isOpen: false,
    });
    try {
      const { data } = await axios.post("/api/payments/upgrade", {
        plan: plan.code,
        userId: user._id,
        paymentId: cardId,
        wordsLimit: plan.wordsLimit,
      });
      if (data.price) {
        toast.success("Your plan has been updated");
        setPLan({
          ...plan,
          ...plans[data.price],
        });
      }
      if(isCanceled){
        setTimeout(() => {
          window.location.reload()
        }, 1000);
        return
      }
      loadUser()
      setLoading(false);
    } catch ({ response }) {
      setLoading(false);
      if (response?.data?.raw?.message) {
        toast.error(response.data.raw.message);
      }
    }
  };

  const cancelSub = async () => {
    const id = user._id;
    setLoading(true);
    setIsOpenCancelModal(false)
    try {
      await axios.post("/api/payments/cancel", { id });
      toast.success("Subscription successfully cancelled", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
      setTimeout(() => {
        window.location.reload()
      }, 1000);
    } catch (err) {
      setLoading(false);
      toast.error("Something went wrong. Please contact support.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const PlanModalContent = (price) => {
    setPlanModalContent({
      ...plans[price],
      isOpen: true,
    });
  };

  const getPayments = async () => {
    return axios.get(`/api/payments/get/${user._id}`);
  };
  const getPlanPrice = async (id) =>
    await axios.post("/api/payments/get-plan", { id });

  useEffect(() => {
    if(user.subscription === 'FREE'){
      setIsFreePlan(true)
    }
    if (
      user._id &&
      user.subId !== "trial" &&
      user.subId !== "active" &&
      !!user.subscription && user.subscription !== 'FREE'
    ) {
      getPlanPrice(user._id)
        .then(({ data: { dollars } }) => {
          if (dollars) {
            setPLan(plans[dollars]);
            if (
              dollars === "$648.00" ||
              dollars === "$215.88" ||
              dollars === "$323.88"
            ) {
              setCheckedType(true);
            }
          }
        })
        .catch(({ response }) => {});
      getPayments()
        .then(({ data }) => {
          setCardList(data.cards);
          setLoading(false);
        })
        .catch(({ response }) => {
          if (response && response.status === 404) {
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
    } else {
      setLoading(false);
    }
  }, []);
  return (
    <div className="profile__biling">
      {loading && <Loader />}
      {!loading && plan && (
        <>
          {plan.name && (
            <div>
              <div className="profile__biling__title">
                Manage Your Subscriptions
              </div>
              <div className="profile__biling__currency">
                <div className="title">Your current plan</div>
                <div className="plan">
                  <div className="plan__left">
                    <div className="plan__name">
                      {plan.name}{" "}
                      <div className="plan__limit">
                        ( {plan.isAnnuall ? "Bill annually" : "Bill monthly"} )
                      </div>
                    </div>
                    <div className="plan__limit">{plan.limit}</div>
                  </div>
                  <div className="plan__price">
                    {plan.price}
                    <span>/ month</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {!loading && (
        <Plans
          plan={plan}
          checkedType={checkedType}
          setCheckedType={setCheckedType}
          user={user}
          isCanceled={isCanceled}
          isFreePlan={isFreePlan}
          setPlan={PlanModalContent}
        />
      )}
      {planModalContent.isOpen && (
        <Modal onClose={() => setPlanModalContent({ isOpen: false })}>
          <AddPlanContent
            onSumbit={onSumbit}
            plan={planModalContent}
            user={user}
            cards={cardList}
            isFreePlan={isFreePlan}
            isConnected={
              user.subscription && user.subId !== "trial" && user.subId !== "active"
            }
          />
        </Modal>
      )}
      {!loading &&
        user.subId !== "trial" &&
        user.subId !== "active" &&
        user.subscription && (
          <Payments
            cardList={cardList}
            setCardList={setCardList}
            userId={user._id}
            user={user}
          />
        )}
      {isOpenCancelModal && (
        <Modal
          onClose={() => {
            setIsOpenCancelModal(false);
          }}
          hasCloseButton={false}
        >
          <CancelModal
            onClose={() => setIsOpenCancelModal(false)}
            onSumbit={() => cancelSub()}
          />
        </Modal>
      )}
      {user.subId !== "trial" &&
        !loading &&
        user.subId !== "active" &&
        user.subscription && (
          <div className="invoices__section">
            <div className="invoices__section__left">
              <div className="title">Invoices</div>
              <div className="subtitle">View your payment history</div>
            </div>
            <button
              onClick={() => history.push("/settings/invoices")}
              className="btn-main"
              disabled={user.subId === 'FREE' }
            >
              View Billing history
            </button>
          </div>
        )}
      {user.subId !== "trial" && !isCanceled && !isFreePlan && 
        !loading &&
        user.subId !== "active" &&
        user.subId && (
          <div className="cancel__section">
            <div className="title">Cancel account</div>
            <div className="subtitle">
              Please be aware that cancelling your account will cause you to
              lose all of your saved <br /> content and earned credits on your
              account
            </div>
            <div
              className="cancel__section__button"
              onClick={() => setIsOpenCancelModal(true)}
            >
              <Icon name="Trash" />
              Cancel account
            </div>
          </div>
        )}
    </div>
  );
};

ProfileBiling.propTypes = {
  logout: PropTypes.func.isRequired,
  user: PropTypes.object,
  isCanceled: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});



export default connect(mapStateToProps, { logout, loadUser: load })(ProfileBiling);
