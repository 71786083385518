import React from "react";
import "./index.scss";
import Icon from "../../common/Icon";

const Ideal = () => {
  return (
    <div className="ideal">
      <div className="ideal__wrapper">
        <div className="ideal__top">
          <span>ResearchAI</span><br /> Is Ideal for
        </div>
        <div className="ideal__content">
          <div className="ideal__item">
            <div className="ideal__item__logo ideal__item__course">
              <Icon name="CourseLanding" />
            </div>
            <div className="ideal__item__title">Course creators</div>
            <div className="ideal__item__content">
              looking to craft informative content that helps empower their
              students to new success
            </div>
          </div>
          <div className="ideal__item">
            <div className="ideal__item__logo ideal__item__marketing">
              <Icon name="RocketLanding" />
            </div>
            <div className="ideal__item__title">Marketing teams</div>
            <div className="ideal__item__content">
              looking to get engaging content that drives interest in courses
              and increases sign-up rates
            </div>
          </div>
          <div className="ideal__item">
            <div className="ideal__item__logo ideal__item__entrepreneurs">
              <Icon name="EntrepreneursLanding" />
            </div>
            <div className="ideal__item__title">Entrepreneurs</div>
            <div className="ideal__item__content">
              looking to create articles, ads, videos, emails, sales letters,
              and more, that communicate powerfully with a target audience
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Ideal.propTypes = {};

export default Ideal;
