import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss'
import userImage from "../../../../assets/images/user.jpg";
import Icon from '../../../common/Icon'
import OutsideClickHandler from 'react-outside-click-handler';
import Modal from 'components/common/Modal';


const InvidetUser = ({user,removeMember,makeAnAdmin}) => {
    const [isOpenTriangle,setIsOpenTriangle] = useState(false)
    const [isOpenDeleteModal,setIsOpenDeleteModal] = useState(false)
    const [id,setId] = useState(null)
    return (
       <div className="invited__user">
            {isOpenDeleteModal && (
               <Modal
                    onClose={()=>setIsOpenDeleteModal(false)}
               >
                    <div className="delete__modal">
                        <div className="delete__modal__top">
                          <div className="delete__modal__title">
                            Are you sure you want to delete this member ?
                          </div>
                        </div>
                        <div className="delete__modal__footer">
                          <button className="btn-second" onClick={() => setIsOpenDeleteModal(false)}>
                            Cancel
                          </button>
                          <button className="btn-main" onClick={() => {
                              setIsOpenDeleteModal(false)
                              removeMember(id)
                          }}>
                            Delete
                          </button>
                        </div>
                    </div>
               </Modal>
           )}
           <div className="invited__user__left">
               <img src={userImage} alt="" />
               <div className="invited__user__left__info">
                   <div className="title">{user.name}</div>
                   <div className="email">{user.email}</div>
               </div>
           </div>
           <div className="invited__user__right">
               {user.role === 0 ? <div className='user__role user__role__member'>Member</div>: <div className='user__role user__role__admin'>Admin</div>}
               <div className="invited__user__right__triangle">
                   <div className="edit__icon" role='presentation' onClick={()=>setIsOpenTriangle(!isOpenTriangle)}>
                        <Icon name='Triangle' color={isOpenTriangle ? '#2F2F2F' : null }/>
                   </div>
                   {isOpenTriangle && (
                        <OutsideClickHandler
                        onOutsideClick={() => {
                            setIsOpenTriangle(false)
                        }}
                      >
                         <div className="opened__menu">
                            <div className="opened__menu__item" role='presentation' onClick={()=>makeAnAdmin(user._id,user.role === 0 ? 1 : 0)}>
                                <Icon name='AddUser' />
                                <div className="" style={{ whiteSpace: 'nowrap' }}>Make an {user.role === 0 ? 'admin' : 'member'}</div>
                            </div>
                            <div className="opened__menu__item" role='presentation' onClick={()=>{
                                setId(user._id)
                                setIsOpenDeleteModal(true)
                                setIsOpenTriangle(false)
                            }}>
                                <Icon name='RemoveUser' />
                                <div className="" style={{ whiteSpace: 'nowrap' }}>Remove</div>
                            </div>
                       </div>
                      </OutsideClickHandler>
                      
                   )}
               </div>
           </div>
       </div>
    );
};

InvidetUser.propTypes = {
    user: PropTypes.object,
    removeMember: PropTypes.func,
    makeAnAdmin: PropTypes.func,
};

export default InvidetUser;