import React from "react";
import PropTypes from "prop-types";
import "./index.scss";
import Icon from "../Icon";

const Modal = ({ children, onClose, hasCloseButton = true }) => {
  return (
    <div className="modal__new">
      <div
        className="modal__new__background"
        role="presentation"
        onClick={() => onClose()}
      ></div>
      <div className="modal__new__content">
        <div className="content">
          <div className="content-w">{children}</div>
          {hasCloseButton && (
            <Icon
              name="Close"
              className="modal__new__close"
              onClick={() => onClose()}
            />
          )}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func,
  hasCloseButton: PropTypes.bool,
};

export default Modal;
