/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Editor from "rich-markdown-editor";
import axios from "axios";
import { remark } from "remark";
import strip from "strip-markdown";
import Loading from "../../common/Loading";
import MegaEditorTools from "./MegaEditorTools";
import MegaHeader from "./MegaHeader";
import Spinner from "../../layout/Spinner";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

toast.configure();

const MegaEditor = ({ data, id }) => {
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [aiLoading, setAiLoading] = useState(false);
  const [editorText, setEditorText] = useState();
  const [saving, setSaving] = useState(false);
  const [title, setTitle] = useState(data ? data.title : "");
  const [noMarkdown, setNoMarkdown] = useState("");
  const [toolSwitch, setToolSwitch] = useState("results");

  const switchTool = (str) => {
    setToolSwitch(str);
  };

  remark()
    .use(strip)
    .process(content)
    .then((file) => {
      setNoMarkdown(String(file));
    });

  const handleSubmit = async () => {
    setAiLoading(true);
    try {
      let promptText;

      content.length < 2000
        ? (promptText = `${content.replace("\\s+", " ").trim()}`)
        : (promptText = `${content.slice(-1400).replace("\\s+", " ").trim()} 
           `);

      //            .replace(/\n/g, "")
      // .replace(/\n/g, "")
      // Paragraph
      let paragraph;
      content.length < 300
        ? (paragraph = `${content.trim()}`)
        : (paragraph = `${content.replace(/\n/g, "")}
           `);
      // Given the above context, write a paragraph for the blog post. Make it engaging.

      // `Write the next sentences of the blog content for the
      // given blog title, blog content, keywords, and about this blog.
      // Blog title: ${title} \n

      let prompt = `Given the topic and content, write a paragraph for the blog article.
      Topic: ${title.replace(/\n/g, "")}
      Content:${promptText}
      `;

      // let newPrompt = `Given the topic, content and paragraph, expand the paragraph:
      // Topic: ${title.replace(/\n/g, "")}

      // Content: ${promptText}

      // Paragraph: ${content.length < 250 ? content : content.slice(-300)}

      // `;
      // console.log(prompt);
      const res = await axios.post("/api/index", {
        text: prompt,
      });
      const data = res.data;
      // console.log(prompt);
      // console.log(data);
      // .replace(/\n/g, " ");
      const cleanText = data["choices"][0]["text"];
      // console.log(cleanText)
      setEditorText(content + cleanText);
      setContent(content + cleanText);
      setAiLoading(false);
    } catch (err) {
      toast.error("Please write some text to continue", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setAiLoading(false);
    }
  };

  useEffect(() => {
    const handleSave = async () => {
      setSaving(true);
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const body = JSON.stringify({ content, title });
        const res = await axios.patch(
          `/api/documents/findone/${id}`,
          body,
          config
        );
        // console.log(res)
        setSaving(false);
      } catch (err) {
        console.log(err.message);
      }
    };
    function autoSave() {
      handleSave();
    }
    setTimeout(() => {
      autoSave();
    }, 7000);
  }, [content, id, title]);

  useEffect(() => {
    function loadData() {
      if (data !== undefined && data.content) {
        setContent(data.content);
        setTitle(data.title);
        setLoading(false);
      } else if (data !== undefined && !data.hasOwnProperty("content")) {
        setContent("");
        setTitle(data.title);
        setLoading(false);
      } else if (data) {
        setContent("");
        setTitle(data.title);
        setLoading(false);
      }
    }
    loadData();
  }, [data]);

  const wordCount = content.split(" ").length;

  if (typeof content === "string" && !loading) {
    return (
      <div>
        <div className="offcanvas-wrap">
          <MegaHeader
            count={wordCount}
            title={title}
            id={id}
            tool={toolSwitch}
            switchFunc={switchTool}
          />
          <section className="split">
            <div className="container-fluid">
              <div className="row justify-content-start">
                <div className="col-lg-5 split-sidebar">
                  <div className="sticky-top">
                    <MegaEditorTools
                      tool={toolSwitch}
                      switchFunc={switchTool}
                    />
                  </div>
                </div>
                <div className="col-lg-7 split-content">
                  <Editor
                    className="p-15"
                    id="markdown"
                    defaultValue={content}
                    onChange={(getValue) => {
                      setContent(getValue());
                    }}
                    autoFocus={true}
                    value={editorText}
                    placeholder="Type / to see the list of commands."
                  />

                  <div id="mybutton" className="justify-content-end">
                    {aiLoading ? (
                      <Loading />
                    ) : (
                      <button
                        onClick={handleSubmit}
                        className="btn btn-sm btn-primary rounded"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Push this to let AI write for you."
                      >
                        Write for me
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
  return <Spinner />;
};

export default MegaEditor;
