import React from "react";
import PropTypes from "prop-types";
import Icon from "../../../../common/Icon";
import "./cards.scss";

export const getcardDate = (date) => {
  return (date + "").slice(0, 2) + "/" + (date + "").slice(2);
};
export const Visa = ({ card,iconName }) => {
  return (
    <div className="payment__card payment__card__visa">
      <div className="payment__card__circle">
        {card.isDefault ? (
          <Icon name="Default" color=" #FFFFFF" />
        ) : (
          <Icon name="Other" color=" #FFFFFF" />
        )}
      </div>
      <div className="payment__card__visa__number payment__card__number">
        •••• •••• •••• {card.last4}
      </div>
      <div className="payment__card__footer">
        <div className="payment__card__visa__date payment__card__date">
          {getcardDate(card.exp_year)}
        </div>
        <Icon name={iconName ? iconName : "Visa"} />
      </div>
    </div>
  );
};

export const Master = ({ card }) => {
  return (
    <div className="payment__card payment__card__master">
      <div className="payment__card__circle">
        {card.isDefault ? (
          <Icon name="Default" color="#9F9F9F" />
        ) : (
          <Icon name="Other" color="#9F9F9F" />
        )}
      </div>
      <div className="payment__card__master__number payment__card__number">
        •••• •••• •••• {card.last4}
      </div>
      <div className="payment__card__footer">
        <div className="payment__card__master__date payment__card__date">
          {getcardDate(card.exp_year)}
        </div>
        <Icon name="Master" />
      </div>
    </div>
  );
};

Visa.propTypes = {
  card: PropTypes.object,
  iconName: PropTypes.string,
};
Master.propTypes = {
  card: PropTypes.object,
};
