/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import SideBar from "./SideBar/SideBar.jsx";
import DocumetnItemNew from "../../common/DocumentItem";
import { useHistory } from 'react-router-dom'
import Loader from "../../common/Loader";
import { setId } from "../../../actions/document";
import { setTemplate } from "../../../actions/templates";
import { tools } from "./newWritingTools";

toast.configure();

const Documents = ({ auth: { user }, setInitialDocumentId, changeTemplate }) => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState();
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading,setIsLoading] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (data && !loading) {
      const results = data.filter((doc) =>
        doc.title ? doc.title.toLowerCase().includes(searchTerm) : false
      );
      setSearchResults(results);
    }
  }, [searchTerm]);
  const handleDelete = async (id) => {
    try {
      setIsLoading(true)
      await axios.delete(`/api/documents/findone/${id}`, { id });
      toast.success("Document deleted.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false)
      const newData = data.filter((item)=>item._id !== id)
      setData(newData)
      setSearchResults(newData)
    } catch (err) {
      setIsLoading(false)
      toast.error("Something went wrong. Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleSubmit = async (id) => {
    try {
      setInitialDocumentId(id)
      history.push("/new-document/editor")
      changeTemplate(tools[0])
    } catch (err) {
      console.error(err.message);
    }
  };
  useEffect(() => {
    const loadDocs = async () => {
      setLoading(true);
      try {
        const res = await axios.get("/api/documents/all");
        const data = res.data;
        setData(data);
        setSearchResults(data);
        setLoading(false);
      } catch (err) {
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      }
    };

    loadDocs();
  }, []);

  return (
    <div>
      {
        // <NavBar />  <Hero user={user && user.name}/>
      }
      {isLoading && (<Loader />)}
      <div className=" space-1 space-bottom-lg-3 ">
        <div className="row">
          <div className="col-lg-3 mb-5 mb-lg-0">
            <div className="mr-lg-3">
              <SideBar user={user && user.name} />
            </div>
          </div>
          <div className="document__wrapper">
            <div className="ml-lg-2 mt-10">
              <div className="row mx-n2 mb-7">
                <div className="container-fluid">
                  <h4 className="text-center mb-5 wrapper__title">My documents</h4>
                  <div className="grouped-inputs p-1 rounded-pill border mb-5">
                    <div className="row g-0">
                      <div className="col">
                        <input
                          type="text"
                          className="form-control form-control-sm px-4 text-primary"
                          placeholder="Search for documents"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>
                      <div className="col-auto d-grid">
                        <span className="btn btn-primary btn-sm btn-icon rounded-circle">
                          <i className="bi bi-search"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="documents__flex" style={{ 
                 }}>
                  {loading ? (
                    <Loader />
                  ) : (
                    searchResults.map((d) => (
                      <DocumetnItemNew onSelect={()=>handleSubmit(d._id)} onDelete={()=>handleDelete(d._id)} key={d._id} title={d.title} id={d._id} name={d.name}/>
                    ))
                  )}
                  {!loading && !searchResults.length && (
                      <>
                      <div></div>
                      <h5 className="text-center mb-5 w-11/12">No result found</h5>
                      <div></div>
                      </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Documents.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStatetoProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setInitialDocumentId: (id) => dispatch(setId(id)),
  changeTemplate: (template) => dispatch(setTemplate(template)),
})

export default connect(mapStatetoProps,mapDispatchToProps)(Documents);
